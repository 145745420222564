import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { GetTheData, GetTheInditualUser } from '../API/Api'

function WooProducts({ phoneNumber }) {


    useEffect(() => {
        FetchingVendors()
    }, [])


    const [vendorData, setVendorData] = useState([])
    async function FetchingVendors() {
        try {
            const response = await GetTheInditualUser('business/individual/' + phoneNumber)
            console.log(response);
            setVendorData(response)
            FetchingProductConfig(response[0])
        } catch (error) {
            console.log(error);
        }
    }

    const [PostORPatch, setPostORPatch] = useState(false)
    const [ProductData, setProductData] = useState(null)
    async function FetchingProductConfig(data) {
        try {
            const response = await GetTheData('productConfiguration')
            const datas = response.find(e => e.businessId == data.id)
            console.log(datas);
            if (datas === undefined) {
                setPostORPatch(true)
            } else {
                setProductData(datas)
                setWeightUnit(datas.weightUnit)
                setDimentionUnit(datas.dimensionsUnit)
                setEnableReviews(datas.enableProductReviews)
                setProductRating(datas.enableStarRating)
            }
        } catch (error) {
            console.log(error);
        }
    }



    const [DropDownState, setDropDownState] = useState(false)
    const [DropDownState1, setDropDownState1] = useState(false)
    const [DropDownState2, setDropDownState2] = useState(false)

    // usestate
    const [weightUnit, setWeightUnit] = useState('Kg')
    const [dimentionUnit, setDimentionUnit] = useState('m')
    const [enableReviews, setEnableReviews] = useState(1)
    const [productRating, setProductRating] = useState(1)

    async function PostingProducts() {
        const data = {
            "weightUnit": weightUnit,
            "dimensionsUnit": dimentionUnit,
            "enableProductReviews": enableReviews,
            "enableStarRating": productRating,
            "businessId": vendorData[0].id,
            "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(),
            "creationTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(),
        }
        try {
            axios.post('https://ecommerce-apis-3h5vkufilq-el.a.run.app/productConfiguration', data)
                .then((response) => {
                    console.log(response.data);
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error.message);
        }
    }

    async function PatchingTheProducts() {
        const data = {
            "weightUnit": weightUnit,
            "dimensionsUnit": dimentionUnit,
            "enableProductReviews": enableReviews,
            "enableStarRating": productRating,
            "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(),
        }

        try {
            axios.patch('https://ecommerce-apis-3h5vkufilq-el.a.run.app/productConfiguration/'+ProductData.id,data)
            .then((response) => {
                console.log(response.data);
                FetchingVendors()
            }).catch((error) => {
                console.log(error.message);
            })

        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Shop pages</h2>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Shop page</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState(!DropDownState)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[4px]'>
                            <h1>india</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState ?
                                <div className='flex flex-col'>
                                    <input type="search" id="default-search"
                                        className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-2 text-gray-900 rounded-sm bg-white " placeholder="" />
                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>Andhara</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>karanataka</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>punjab</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>mumbai</h1>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Add to cart behaviour</h2>
                    <div className='max-w-sm w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'> Redirect to the cart page after successful addition</h2>
                        </div>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Enable AJAX add to cart buttons on archives</h2>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Placeholder image</h2>
                    <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                    <h2 className='font-semibold text-lg text-primaryGrayDark'>General options</h2>
                    <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                        <h2 className='font-semibold text-sm text-primaryGrayDark'>Default customer location</h2>
                        <div className={`border bg-white rounded-md  ${DropDownState1 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                            <div onClick={() => setDropDownState1(!DropDownState1)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[4px]'>
                                <h1 className='text-sm'>{weightUnit}</h1>
                                <FaAngleDown className='' />
                            </div>
                            {
                                DropDownState1 ?
                                    <div className='flex flex-col'>
                                        <h1 onClick={() => setDropDownState1(false) & setWeightUnit('Kg')} className='w-full cursor-pointer text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 mt-2'>Kg</h1>
                                        <h1 onClick={() => setDropDownState1(false) & setWeightUnit('g')} className='w-full cursor-pointer text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>g</h1>
                                        <h1 onClick={() => setDropDownState1(false) & setWeightUnit('lbs')} className='w-full cursor-pointer text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>lbs</h1>
                                        <h1 onClick={() => setDropDownState1(false) & setWeightUnit('oz')} className='w-full cursor-pointer text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>oz</h1>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                        <h2 className='font-semibold text-sm text-primaryGrayDark'>Default customer location</h2>
                        <div className={`border bg-white rounded-md relative  ${DropDownState2 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                            <div onClick={() => setDropDownState2(!DropDownState2)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[4px]'>
                                <h1 className='text-sm'>{dimentionUnit}</h1>
                                <FaAngleDown className='' />
                            </div>
                            {
                                DropDownState2 ?
                                    <div className='flex flex-col'>
                                        <h1 onClick={() => setDropDownState2(false) & setDimentionUnit('m')} className='w-full text-sm hover:text-white  bg-transparent hover:bg-primaryBlue p-2 mt-2'>m</h1>
                                        <h1 onClick={() => setDropDownState2(false) & setDimentionUnit('cm')} className='w-full  text-sm hover:text-white bg-transparent hover:bg-primaryBlue p-2'>cm</h1>
                                        <h1 onClick={() => setDropDownState2(false) & setDimentionUnit('mm')} className='w-full  text-sm hover:text-white bg-transparent hover:bg-primaryBlue p-2'>mm</h1>
                                        <h1 onClick={() => setDropDownState2(false) & setDimentionUnit('in')} className='w-full  text-sm hover:text-white bg-transparent hover:bg-primaryBlue p-2'>in</h1>
                                        <h1 onClick={() => setDropDownState2(false) & setDimentionUnit('yd')} className='w-full  text-sm hover:text-white bg-transparent hover:bg-primaryBlue p-2'>yd</h1>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                    <h2 className='font-semibold text-lg text-primaryGrayDark'>Reviews</h2>
                    <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                        <h2 className='font-semibold text-sm text-primaryGrayDark'>Enable reviews</h2>
                        <div className='max-w-sm w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                            <div className='flex  flex-row self-start  items-center gap-3'>
                                <input checked={enableReviews == 1 ? true : false} onClick={() => setEnableReviews(enableReviews == 0 ? 1 : 0)} className='cursor-pointer' type="checkbox" />
                                <h2 className=' text-sm text-primaryGrayDark'>Enable product reviews</h2>
                            </div>
                            {enableReviews == 1 ?
                                <>
                                    <div className='flex  flex-row self-start  items-center gap-3'>
                                        <input type="checkbox" className='cursor-pointer' />
                                        <h2 className=' text-sm text-primaryGrayDark'>Show "verified owner" label on customer reviews</h2>
                                    </div>
                                    <div className='flex  flex-row self-start  items-center gap-3'>
                                        <input type="checkbox" />
                                        <h2 className=' text-sm text-primaryGrayDark'>Reviews can only be left by "verified owners"</h2>
                                    </div>
                                </> : null}
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                        <h2 className='font-semibold text-sm text-primaryGrayDark'>Product ratings</h2>
                        <div className='max-w-sm w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                            <div className='flex  flex-row self-start  items-center gap-3'>
                                <input checked={productRating == 1 ? true : false} onClick={() => setProductRating(productRating == 0 ? 1 : 0)} type="checkbox" />
                                <h2 className=' text-sm text-primaryGrayDark'>Enable star rating on reviews</h2>
                            </div>
                            {productRating == 1 ? <div className='flex  flex-row self-start  items-center gap-3'>
                                <input type="checkbox" />
                                <h2 className=' text-sm text-primaryGrayDark'> Star ratings should be required, not optional</h2>
                            </div> : null}
                        </div>
                    </div>
                    <div onClick={() => PostORPatch ? PostingProducts() : PatchingTheProducts()} className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col my-10 items-center justify-center w-max px-[7px] py-1'>
                        <h5>Save Changes</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WooProducts