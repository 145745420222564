import React, { useEffect, useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import placeholder from '../Assets/placeholder.png'
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { GetTheData, GetTheInditualUser } from '../API/Api';
function WooGeneral({ phoneNumber }) {
    console.log(phoneNumber);
    const [DropDownState, setDropDownState] = useState(false)
    const [DropDownState1, setDropDownState1] = useState(false)
    const [DropDownState2, setDropDownState2] = useState(false)


    // usestate

    const [Name, setName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [planType, setPlanType] = useState('Base')
    const [GstNo, setGstNo] = useState('')
    const [ProductImage1, setProductImage1] = useState(null)
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('TamilNadu')
    const [pincode, setPincode] = useState('')
    const [enabletaxes, setEnabletaxes] = useState(0)
    const [couponType, setCouponType] = useState('None')
    const [currency, setCurrency] = useState('')
    const [currencyPosition, setCurrencyPosition] = useState('Left')
    const [thounsandSeparater, setThounsandSeparater] = useState(',')
    const [decimalSeperator, setDecimalSeperator] = useState('.')
    const [numOfDecibals, setNumOfDecibals] = useState(0)

    // PostFunction

    useEffect(() => {
        FetchingVendors()
    }, [])


    const [vendorData, setVendorData] = useState([])
    async function FetchingVendors() {
        try {
            const response = await GetTheInditualUser('business/individual/' + phoneNumber)
            console.log(response);
            setVendorData(response)
            SettingFunction(response[0])
        } catch (error) {
            console.log(error);
        }
    }

    function SettingFunction(datas) {
        console.log(datas);
        setName(datas.name)
        setMobileNumber(datas.mobileNumber)
        setBusinessName(datas.businessName)
        setGstNo(datas.gstNumber)
        setAddress1(JSON.parse(datas.address).b)
        setProductImage1(datas.profilePictureUrl)
        setAddress2(JSON.parse(datas.address).a)
        setCity(JSON.parse(datas.address).c)
        setCountry(JSON.parse(datas.address).s)
        setPincode(JSON.parse(datas.address).p)
        setEnabletaxes(datas.enableTaxes)
        setCouponType(datas.couponType)
        setCurrency(datas.currency)
        setCurrencyPosition(datas.currencyPosition)
        setThounsandSeparater(datas.thousandSeparator)
        setDecimalSeperator(datas.descimalSeperator)
        setNumOfDecibals(datas.numberOfDecimals)
    }
    async function PatchTheGeneralDetails() {
        const address = {
            'b': address1, 'a': address2, 'c': city, 's': country, 'p': pincode
        }
        const data = {
            "name": Name,
            "address": JSON.stringify(address),
            "enableTaxes": enabletaxes,
            "couponType": couponType,
            "currency": currency,
            "currencyPosition": currencyPosition,
            "thousandSeparator": thounsandSeparater,
            "descimalSeperator": decimalSeperator,
            "numberOfDecimals": numOfDecibals,
            "updatedTime": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(),
            "mobileNumber": mobileNumber,
            "profilePictureUrl": ImageCallingSwap ? null : ProductImage1,
            "businessName": businessName,
            "gstNumber": GstNo,
        }
        try {
            axios.patch('https://ecommerce-apis-3h5vkufilq-el.a.run.app/business/' + vendorData[0].id, data)
                .then((response) => {
                    console.log(response.data);
                    if (ImageCallingSwap) {
                        AddToStorageBucket(ProductImage1, vendorData[0].id, 'profilePictureUrl')
                    }else{
                        alert('Successfully Updated')
                        FetchingVendors()
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
            console.log(data);
        } catch (error) {
            console.log(error.message);
        }
    }

    function AddToStorageBucket(e, _id, path) {
        let formdata = new FormData()
        formdata.append('file', e)
        try {
            axios.post('https://ecommerce-apis-3h5vkufilq-el.a.run.app/upload/business/' + _id, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response.data);
                    PathchTheImagesName(e, _id, path)
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error.message);
        }
    }

    function PathchTheImagesName(e, id, _path) {
        let data = {};
        if (_path === 'profilePictureUrl') {
            data = {
                "profilePictureUrl": e.name
            }
        } 
        console.log(data);
        try {
            axios.patch('https://ecommerce-apis-3h5vkufilq-el.a.run.app/business/' + id, data)
                .then((response) => {
                    console.log(response.data);
                    FetchingVendors()
                    alert('Successfully Updated')
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    const [ImageCallingSwap, setImageCallingSwap] = useState(false)
    function HandleLogo(e) {
        setProductImage1(e)
        setImageCallingSwap(true)
    }
    return (
        <div className='flex flex-col'>
            <ToastContainer position="top-center"
                autoClose={5000}
                closeOnClick={false}
                pauseOnHover={false}
                theme="dark"
                transition={Zoom} />
            <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Store Address</h2>
                <h5 className='text-primaryGrayDark'>This is where your business is located. Tax rates and shipping rates will use this address.</h5>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Name</h2>
                    <input value={Name} onChange={(e) => setName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Mobile Number</h2>
                    <input value={mobileNumber} maxLength={10} onChange={(e) => setMobileNumber(e.target.value)} type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Business Name</h2>
                    <input value={businessName} onChange={(e) => setBusinessName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>GST Number</h2>
                    <input value={GstNo} maxLength={15} onChange={(e) => setGstNo(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                {/* <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Plan Type</h2>
                    <div className='max-w-sm w-full gap-2  lg:mt-0'>
                        <select onChange={(e) => setPlanType(e.target.value)} className='outline-none p-2 text-sm rounded-md'>
                            <option className='text-sm' value={'Base'}>Base</option>
                            <option className='text-sm' value={'Premium'}>Premium</option>
                        </select>
                    </div>
                </div> */}
                <div className='flex flex-row mt-5 justify-between gap-3'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Company Logo</h2>
                    <div className='flex flex-row gap-5 max-w-sm w-full'>
                        {ProductImage1 == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> :
                            ImageCallingSwap == false ? <img className='w-[70px] h-[70px] rounded-md' src={"https://storage.googleapis.com/ecommercestorage/business/" + vendorData[0].id + '/' + ProductImage1} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage1)} />}
                        <input onChange={(e) => HandleLogo(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                    </div>
                </div>
                {/* <div className='flex flex-row mt-5 justify-between gap-3'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>GST gstCertificate Image</h2>
                    <div className='flex flex-row gap-5 max-w-sm w-full'>
                        {ProductImage2 == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage2)} />}
                        <input onChange={(e) => setProductImage2(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                    </div>
                </div> */}
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Address line 1</h2>
                    <input value={address1} onChange={(e) => setAddress1(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Address line 2</h2>
                    <input value={address2} onChange={(e) => setAddress2(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>City</h2>
                    <input value={city} onChange={(e) => setCity(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Country / State</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState(!DropDownState)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                            <h1>{country}</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState ?
                                <div className='flex flex-col'>
                                    <input type="search" id="default-search"
                                        className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-2 text-gray-900 rounded-sm bg-white " placeholder="" />
                                    <h1 onClick={() => setDropDownState(false) & setCountry('TamilNadu')} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                    <h1 onClick={() => setDropDownState(false) & setCountry('Andhara')} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Andhara</h1>
                                    <h1 onClick={() => setDropDownState(false) & setCountry('karanataka')} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>karanataka</h1>
                                    <h1 onClick={() => setDropDownState(false) & setCountry('punjab')} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>punjab</h1>
                                    <h1 onClick={() => setDropDownState(false) & setCountry('mumbai')} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>mumbai</h1>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Postcode / ZIP</h2>
                    <input value={pincode} onChange={(e) => setPincode(e.target.value)} type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
            </div>
            <div className='flex flex-col mt-10 gap-3 max-w-xl'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>General options</h2>
                {/* <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Selling location(s)</h2>
                    <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>

                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Sell to all countries, except for…</h2>
                    <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Default customer location</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState1 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState1(!DropDownState1)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                            <h1>india</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState1 ?
                                <div className='flex flex-col'>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Andhara</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>karanataka</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>punjab</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>mumbai</h1>
                                </div>
                                : null
                        }
                    </div>
                </div> */}
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Enable taxes	</h2>
                    <div className='max-w-sm w-full gap-2 mt-5 lg:mt-0'>
                        <div className='flex flex-row self-start  items-center gap-3'>
                            <input checked={enabletaxes == 1 ? true : false} onChange={() => setEnabletaxes(enabletaxes == 0 ? 1 : 0)} type="checkbox" />
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Enable tax rates and calculations</h2>
                        </div>
                        <h5 className='text-primaryGrayDark pl-5'>Coupons can be applied from the cart and checkout pages.</h5>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Enable coupons</h2>
                    {/* <div className='max-w-sm w-full gap-2 mt-5 lg:mt-0'>
                        <div className='flex flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Enable tax rates and calculations</h2>
                        </div>
                        <h5 className='text-primaryGrayDark pl-5'>Coupons can be applied from the cart and checkout pages.</h5>
                    </div> */}
                    <div className='max-w-sm w-full gap-2  lg:mt-0'>
                        <select value={couponType} onChange={(e) => setCouponType(e.target.value)} className='outline-none p-2 text-sm rounded-md'>
                            <option className='text-sm' value={'None'}>None</option>
                            <option className='text-sm' value={'SingleCoupon'}>Single Coupon</option>
                            <option className='text-sm' value={'MultiCoupons'}>Multi Coupons</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='flex flex-col mt-10 gap-3 max-w-xl'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Currency options</h2>
                <h5 className='text-primaryGrayDark text-sm'>The following options affect how prices are displayed on the frontend.</h5>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Currency</h2>
                    <input value={currency} onChange={(e) => setCurrency(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Currency position</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState2 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState2(!DropDownState2)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                            <h1>{currencyPosition}</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState2 ?
                                <div className='flex flex-col'>
                                    <h1 onClick={() => setDropDownState2(false) & setCurrencyPosition('Left')} className='w-full text-sm bg-transparent hover:bg-primaryBlue hover:text-white p-2 mt-2'>Left</h1>
                                    <h1 onClick={() => setDropDownState2(false) & setCurrencyPosition('Right')} className='w-full text-sm bg-transparent hover:bg-primaryBlue hover:text-white p-2'>Right</h1>
                                    <h1 onClick={() => setDropDownState2(false) & setCurrencyPosition('Left with space')} className='w-full text-sm bg-transparent hover:bg-primaryBlue hover:text-white p-2'>Left with Space</h1>
                                    <h1 onClick={() => setDropDownState2(false) & setCurrencyPosition('Right with space')} className='w-full text-sm bg-transparent hover:bg-primaryBlue hover:text-white p-2'>Right with space</h1>
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between  mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Thousand separator</h2>
                    <div className='w-full max-w-sm'>
                        <input value={thounsandSeparater} maxLength={1} onChange={(e) => setThounsandSeparater(e.target.value)} type="text" id="default-search"
                            className=" focus:outline-primaryBlue focus:border-none border max-w-[70px] w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Decimal separator</h2>
                    <div className='w-full max-w-sm'>
                        <input value={decimalSeperator} onChange={(e) => setDecimalSeperator(e.target.value)} type="text" maxLength={1} id="default-search"
                            className=" focus:outline-primaryBlue focus:border-none border max-w-[70px] w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Number of decimals</h2>
                    <div className='w-full max-w-sm'>
                        <input value={numOfDecibals} min={0} onChange={(e) => setNumOfDecibals(e.target.value)} type="number" id="default-search"
                            className=" focus:outline-primaryBlue focus:border-none border max-w-[70px] w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                    </div>
                </div>
            </div>
            <div onClick={() => PatchTheGeneralDetails()} className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col my-10 items-center justify-center w-max px-[7px] py-1'>
                <h5>Save Changes</h5>
            </div>
        </div>
    )
}

export default WooGeneral