import React, { useEffect, useState } from 'react'
import placeholder from '../Assets/placeholder.png'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import gridimage1 from '../Assets/GirlImage1.jpg'
import gridimage11 from '../Assets/GirlImage11.jpg'
import gridimage2 from '../Assets/GirlImage2.jpg'
import gridimage22 from '../Assets/GirlImage22.jpg'
import gridimage3 from '../Assets/GirlImage3.jpg'
import gridimage33 from '../Assets/GirlImage33.jpg'
import gridimage4 from '../Assets/GirlImage4.jpg'
import gridimage44 from '../Assets/GirlImage44.jpg'
import gridimage5 from '../Assets/GirlImage5.jpg'
import gridimage55 from '../Assets/GirlImage66.jpg'
import gridimage6 from '../Assets/GirlImage7.jpg'
import gridimage66 from '../Assets/GirlImage77.jpg'
import axios from 'axios'
function ProductTags() {
    const [ProductImage, setProductImage] = useState(null)
    const Headerinput = ['Name', 'Description', 'Slug', 'Count']
    const Grid2Collection = [
        {
            id: 1,
            firstImage: gridimage1,
            secondImage: gridimage11,
            title: 'Stuffed Blue Shark',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
        {
            id: 2,
            firstImage: gridimage2,
            secondImage: gridimage22,
            title: 'Denim Jumpsuit',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
        {
            id: 3,
            firstImage: gridimage3,
            secondImage: gridimage33,
            title: 'Super Star Knit Hat',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
        {
            id: 4,
            firstImage: gridimage4,
            secondImage: gridimage44,
            title: 'Denim Shorts',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
        {
            id: 5,
            firstImage: gridimage5,
            secondImage: gridimage55,
            title: 'Funny Bunny Jumpsuit',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
        {
            id: 6,
            firstImage: gridimage6,
            secondImage: gridimage66,
            title: 'Pooh Bear',
            desc: '-',
            slug: 'Toys',
            count: 5
        },
    ]

    const [tagName, setTagName] = useState('')
    const [tagSlug, setTagSlug] = useState('')
    const [tagDesc, setTagDesc] = useState('')
    const [tagSubtitle, setTagSubtitle] = useState('')

    function PostingTheTag() {
        try {
            const data = {
                "tag_name": tagName,
                "tag_desc": tagDesc,
                "background_image": ProductImage.name,
                "subtitle": tagSubtitle,
                "tag_slug": tagSlug
            }
            axios.post('http://localhost:3100/PostHatchTags', data)
                .then((response) => {
                    if (response.status == 200) {
                        FetchingTags()
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        FetchingTags()
    }, [])

    const [tagsData, setTagsData] = useState([])
    async function FetchingTags() {
        try {
            await axios.get('http://localhost:3100/getHatchTags')
                .then((response) => {
                    if (response.status == 200) {
                        setTagsData(response.data)
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    const [hoverItemShow, setHoverItemShow] = useState(-1)

    const [ItemID, setItemID] = useState(-1)
    const [showEditPage, setShowEditPage] = useState(false)
    function OpenEditpageFunction(e) {
        setItemID(e.tag_id)
        setTagName(e.tag_name)
        setTagSlug(e.tag_slug)
        setTagDesc(e.tag_desc)
        setTagSubtitle(e.subtitle)
        setShowEditPage(true)
    }
    async function PatchingFunction() {
        const data =
        {
            "tag_name": tagName,
            "tag_desc": tagDesc,
            "subtitle": tagSubtitle,
            "tag_slug": tagSlug
            // "thumbnail":ProductImage!=null? ProductImage.name:'',
            // "background_image": ProductImage1!=null?ProductImage1.name:''
        }
        try {
            axios.patch('http://localhost:3100/PatchHatchTags/' + ItemID, data)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data);
                        setShowEditPage(false)
                        FetchingTags()
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    async function DeleteFunction() {
        try {
            axios.delete('http://localhost:3100/DeleteHatchTags/' + ItemID)
                .then((response) => {
                    if (response.status == 200) {
                        setShowEditPage(false)
                        FetchingTags()
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <>
            {showEditPage == false ? <div className='w-full bg-[#f0f0f1] pb-10'>
                <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Product tags</div>
                <div className='my-5 flex flex-col md:flex-row md:items-center px-5 gap-1'>
                    <div className='text-2xl'>Product tags</div>
                </div>
                <div className='p-5 '>
                    <div className='w-full flex flex-col lg:flex-row  gap-10'>
                        <div className='lg:w-[40%] w-full'>
                            <div className='font-semibold text-sm'>Add new tag</div>
                            <div className='flex flex-col mt-5 '>
                                <h2 className=' text-sm w-full text-primaryGrayDark'>Name</h2>
                                <input onChange={(e) => setTagName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                                <h3 className='text-sm text-primaryDarkLight'>The name is how it appears on your site.</h3>
                            </div>
                            <div className='flex flex-col mt-5 '>
                                <h2 className=' text-sm w-full text-primaryGrayDark'>Slug</h2>
                                <input onChange={(e) => setTagSlug(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                                <h3 className='text-sm text-primaryDarkLight'>The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</h3>
                            </div>
                            {/* <div className='w-full flex flex-col gap-3 mt-5 '>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Parent Categories</h2>
                            <select className='p-1 text-sm w-40 focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-md'>
                                {
                                    categories.map((e, index) =>
                                        <option key={index} className='text-sm'>{e.cat1}</option>

                                    )
                                }
                            </select>
                        </div> */}
                            <div className='flex flex-col mt-5 '>
                                <h2 className=' text-sm w-full text-primaryGrayDark'>Description</h2>
                                <textarea onChange={(e) => setTagDesc(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 h-40 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                                <h3 className='text-sm text-primaryDarkLight'>The description is not prominent by default; however, some themes may show it.</h3>
                            </div>
                            <div className='flex flex-col mt-5 gap-3'>
                                <h2 className=' text-sm w-full text-primaryGrayDark'>Thumbnail</h2>
                                <div className='flex flex-row gap-5 w-full'>
                                    {ProductImage == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage)} />}
                                    <input onChange={(e) => setProductImage(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                                </div>
                            </div>
                            {/* <div className='flex flex-col mt-5 gap-3'>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Title background image</h2>
                            <div className='flex flex-row gap-5 w-full'>
                                {ProductImage1 == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage1)} />}
                                <input onChange={(e) => setProductImage1(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                            </div>
                        </div> */}
                            <div className='flex flex-col mt-5 '>
                                <h2 className=' text-sm w-full text-primaryGrayDark'>Subtitle</h2>
                                <input onChange={(e) => setTagSubtitle(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                            </div>
                            <div className='flex flex-col my-5'>
                                <div onClick={() => PostingTheTag()} className='bg-primaryBlue cursor-pointer rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                                    <h5>Add new tag</h5>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-[60%] w-full '>
                            <div className='w-full overflow-auto'>
                                <table className='w-full border border-primaryDarkLight'>
                                    <colgroup>
                                        <col className='w-[3%]  ' />
                                        <col className='w-[30%] ' />
                                        <col className='w-[35%] ' />
                                        <col className='w-[30%] ' />
                                        <col className='w-[5%] ' />
                                    </colgroup>
                                    <thead className='border-b border-b-primaryDarkLight'>
                                        <tr>
                                            <th>
                                                <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                                            </th>
                                            <th className='text-primaryBlue '>{Headerinput[0]}</th>
                                            <th className='text-primaryBlue '>{Headerinput[1]}</th>
                                            <th >{Headerinput[2]}</th>
                                            <th className='text-primaryBlue '>{Headerinput[3]}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tagsData.map((e, index) =>
                                                <tr onMouseEnter={() => setHoverItemShow(index)} onMouseLeave={() => setHoverItemShow(-1)} className={`${index % 2 != 0 ? 'bg-primaryWhite' : ''}`} key={index} >
                                                    <td>
                                                        <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                                                    </td>
                                                    <td>
                                                        <h6 className='text-primaryBlue font-bold'>{e.tag_name}</h6>
                                                        {hoverItemShow == index ? <h6 className='text-sm text-primaryBlue mt-1'><span className='text-primaryDarkLight'>ID:2965</span>| <span onClick={() => OpenEditpageFunction(e)}>Edit</span>  | <span className='text-primaryRed'>Trash</span> | Dublicate | Edit With Page Builder</h6> : null}
                                                    </td>
                                                    <td >{e.tag_desc}</td>
                                                    <td className=''>{e.tag_slug}</td>
                                                    <td >{tagsData.length}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    <tbody>
                                        <tr className='border-t border-primaryDarkLight'>
                                            <th>
                                                <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                                            </th>
                                            <th className='text-primaryBlue '>{Headerinput[0]}</th>
                                            <th className='text-primaryBlue '>{Headerinput[1]}</th>
                                            <th >{Headerinput[2]}</th>
                                            <th className='text-primaryBlue '>{Headerinput[3]}</th>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <div className='w-full bg-[#f0f0f1] pb-10'>
                    <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Edit categories</div>
                    <div className='p-5 max-w-xl '>
                        <div className=' text-2xl mb-10 text-primaryGrayDark'>Edit category</div>
                        <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Name</h2>
                            <input value={tagName} onChange={(e) => setTagName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Slug</h2>
                            <input value={tagSlug} onChange={(e) => setTagSlug(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Description</h2>
                            <textarea value={tagDesc} onChange={(e) => setTagDesc(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Background Image</h2>
                            <div className='max-w-sm flex flex-row items-center gap-3'>
                                {ProductImage == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage)} />}
                                <input onChange={(e) => setProductImage(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
                            <h2 className='font-semibold text-sm text-primaryGrayDark'>Subtitle</h2>
                            <input value={tagSubtitle} onChange={(e) => setTagSubtitle(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                        </div>
                        <div className='flex flex-row gap-5 items-center my-10'>
                        <div onClick={() => PatchingFunction()} className='bg-primaryBlue cursor-pointer rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                            <h5>Update</h5>
                        </div>
                        <h5 onClick={() => DeleteFunction()} className='text-red-500 cursor-pointer hover:font-bold'>Delete</h5>
                    </div>
                    </div>
                </div>}
        </>
    )
}

export default ProductTags