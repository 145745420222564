import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { CgNotes } from 'react-icons/cg'
import { CiLollipop, CiMemoPad } from 'react-icons/ci'
import { FaAngleDown, FaAngleUp, FaRegEye } from 'react-icons/fa'
import { FaCalendarDays } from 'react-icons/fa6'
import { LuLink } from 'react-icons/lu'
import { MdLocalShipping } from 'react-icons/md'
import { RiSettings5Fill } from 'react-icons/ri'
import { TiArrowSortedUp } from 'react-icons/ti'
import { VscSymbolProperty } from 'react-icons/vsc'

function AddProduct({ CategorySelection, data }) {
    const [SwapCategories, setSwapCategories] = useState('General')
    const [CategoriesSwap, setCategoriesSwap] = useState('All')

    function GalleryFunction(e) {
        if (data !== undefined && data != null) {
            let temp1 = [...imageUpdateState]
            temp1[1] = true
            setImageUpdateState(temp1)
        }
        let temp = [...e.target.files]
        setProductGallery(temp)
        console.log(temp.length);
    }
    useEffect(() => {
        FetchingCategory()
    }, [])

    const [CategoryData, setCategoryData] = useState([])
    async function FetchingCategory() {
        try {
            await axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory')
                .then((response) => {
                    if (response.status == 200) {
                        setCategoryData(response.data)
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {

        }
    }

    const [productName, setProductName] = useState('')
    const [productDesc, setProductDesc] = useState('')
    const [regularPrize, setRegularPrize] = useState(0)
    const [salePrize, setSalePrize] = useState(0)
    const [Sku, setSku] = useState(0)
    const [SkuStatus, setSkuStatus] = useState('In Stock')
    const [parentCategory, setParentCategory] = useState(0)
    const [videoUrl, setVideoUrl] = useState('')
    const [ProductImage, setProductImage] = useState('')
    const [ProductGallery, setProductGallery] = useState(null)



    async function PostingTheProduct() {
        const data = {
            "product_name": productName,
            "product_desc": productDesc,
            "regular_prize": regularPrize,
            "sale_prize": salePrize,
            "sku": Sku,
            "stock_status": SkuStatus,
            "parent_category": parentCategory,
            "video_url": videoUrl,
            "Posted_Date": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds()
        }
        try {
            await axios.post('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PostProducts', data)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data);
                        AddtoStorage(ProductImage, response.data.insertId)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
            console.log(error);
        }
    }
    async function AddtoStorage(e, _id) {
        const formdata = new FormData()
        formdata.append('file', e)
        try {
            await axios.post('https://cbdconsole-6zwsgvs5iq-el.a.run.app/Upload/ProductImage', formdata, {
                headers:
                {
                    "Content-Type": 'multipart/form-data'
                }
            }).then((responce) => {
                if (responce.status == 200) {
                    console.log(responce.data);
                    const data1 = {
                        "thumbnail": ProductImage.name
                    }
                    PatchingName(_id, data1)
                    if (data==undefined) {
                        AddtoStorageBackground(ProductGallery, _id)
                    }

                }
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const imagepatchCount = useRef(0)
    async function PatchingName(_id, obj) {
        try {
            await axios.patch('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PatchProducts/' + _id, obj)
                .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                        console.log(response.data);
                        imagepatchCount.current += 1
                        if (imagepatchCount.current == 2) {
                            CategorySelection(0)
                        }
                        if(imageUpdateState[0]==true||imageUpdateState[1]==true){
                            CategorySelection(0)
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    async function AddtoStorageBackground(e, _id) {
        let temp = []
        for (let i = 0; i < e.length; i++) {
            const formdata = new FormData()
            formdata.append('file', e[i])
            try {
                await axios.post('https://cbdconsole-6zwsgvs5iq-el.a.run.app/Upload/ProductImage', formdata, {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data'
                    }
                }).then((responce) => {
                    if (responce.status == 200) {
                        console.log(responce);
                        temp.push(e[i].name)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
            } catch (error) {
                console.log(error);
            }
        }
        const data1 = {
            "banner_url": JSON.stringify(temp)
        }
        PatchingName(_id, data1)
    }

    useEffect(() => {
        if (data !== undefined && data != null) {
            StateUpdateFunction(data)
        }
    }, [])
    function StateUpdateFunction(params) {
        console.log(params);
        if (typeof params) {
            setProductName(params.product_name)
            setProductDesc(params.product_desc)
            setRegularPrize(params.regular_prize)
            setSalePrize(params.sale_prize)
            setParentCategory(params.parent_category)
            setVideoUrl(params.video_url)
            setProductImage(params.thumbnail)
            setProductGallery(JSON.parse(params.banner_url))
        }

    }
    const [showQuantity, setShowQuantity] = useState(false)
    const [imageUpdateState, setImageUpdateState] = useState([false, false])
    async function PatchingFunction(_id) {
        const data = {
            "product_name": productName,
            "product_desc": productDesc,
            "regular_prize": regularPrize,
            "sale_prize": salePrize,
            "sku": Sku,
            "stock_status": SkuStatus,
            "parent_category": parentCategory,
            "video_url": videoUrl,
            "Posted_Date": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + '-' + new Date().getUTCHours() + '-' + new Date().getUTCMinutes() + '-' + new Date().getUTCSeconds(),
            "thumbnail": imageUpdateState[0] == true ? null : ProductImage,
            "banner_url": imageUpdateState[1] == true ? null : JSON.stringify(ProductGallery)
        }
        try {
            await axios.patch('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PatchProducts/' + _id, data)
                .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                        if (imageUpdateState[0] == true) {
                            AddtoStorage(ProductImage, _id)
                        }
                        if(imageUpdateState[1] == true){
                            AddtoStorageBackground(ProductGallery, _id)
                        }
                        if(imageUpdateState[0] == false&&imageUpdateState[1]==false){
                            CategorySelection(0)
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <div className='w-full bg-[#f0f0f1] pb-10'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Add New</div>
            <div className='p-5'>
                <div className=' text-2xl text-primaryGrayDark'>Add new product</div>
                <div className='flex flex-col 2xl:flex-row w-full gap-8 my-3 '>
                    <div className='2xl:w-[83%] w-full'>
                        <input value={productName} onChange={(e) => setProductName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue w-full focus:border-none border  text-2xl px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="Product name" />
                        <div className='border  my-5'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product data ---</h3>
                                    <select className='p-1 w-[230px] text-[14px] font-bold text-primaryBlue focus:outline-primaryBlue focus:border-none border  rounded-sm'>
                                        <option className='font-bold '>Primary Type</option>
                                        <option className=' ml-5'>Simple Product</option>
                                        <option className=' ml-5'>Edit</option>
                                        <option className=' ml-5'>move to trash</option>
                                    </select>
                                    <div className='text-primaryDarkLight'>|</div>
                                    <div className='flex flex-row gap-2'>
                                        <h3 className='text-primaryGrayDark font-semibold text-xs'>Virtual:</h3>
                                        <input type="checkbox" />
                                    </div>
                                    <div className='flex flex-row gap-2'>
                                        <h3 className='text-primaryGrayDark font-semibold text-xs'>Downloadable:</h3>
                                        <input type="checkbox" />
                                    </div>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='w-full flex flex-row '>
                                <div className='w-[20%] flex flex-col'>
                                    <div onClick={() => setSwapCategories('General')} className={`${SwapCategories == 'General' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex flex-row  items-center border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <VscSymbolProperty className='w-4 h-4' />
                                        <h6 className='text-sm'>General</h6>
                                    </div>
                                    <div onClick={() => setSwapCategories('Inventory')} className={`flex ${SwapCategories == 'Inventory' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex-row items-center  border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <CiMemoPad className='w-4 h-4' />
                                        <h6 className='text-sm'>Inventory</h6>
                                    </div>
                                    <div onClick={() => setSwapCategories('Shipping')} className={`flex ${SwapCategories == 'Shipping' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex-row items-center  border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <MdLocalShipping className='w-4 h-4' />
                                        <h6 className='text-sm'>Shipping</h6>
                                    </div>
                                    <div onClick={() => setSwapCategories('LinkedProducts')} className={`flex ${SwapCategories == 'LinkedProducts' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex-row items-center  border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <LuLink className='w-4 h-4' />
                                        <h6 className='text-sm'>Linked Products</h6>
                                    </div>
                                    <div onClick={() => setSwapCategories('Attribute')} className={`flex ${SwapCategories == 'Attribute' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex-row items-center  border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <CgNotes className='w-4 h-4' />
                                        <h6 className='text-sm'>Attribute</h6>
                                    </div>
                                    <div onClick={() => setSwapCategories('Advanced')} className={`flex ${SwapCategories == 'Advanced' ? 'bg-[#dcdcde] text-primaryBlue' : 'text-primaryGrayDark'} flex-row items-center  border border-t-0 cursor-pointer px-2 py-3 gap-3`}>
                                        <RiSettings5Fill className='w-4 h-4' />
                                        <h5 className='text-primaryGrayDark'>Advanced</h5>
                                    </div>
                                </div>
                                <div className='w-[80%] bg-white overflow-auto'>
                                    {SwapCategories == 'General' ?
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Regular price (₹)</h2>
                                                <input value={regularPrize} onChange={(e) => setRegularPrize(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Sale price (₹)</h2>
                                                <input value={salePrize} onChange={(e) => setSalePrize(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Sale price dates</h2>
                                                <div className='flex flex-col gap-2 w-full'>
                                                    <input type="date" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                                    <input type="date" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                                </div>
                                            </div>
                                        </div> : null}
                                    {SwapCategories == 'Inventory' ?
                                        <div className='flex flex-col h-[260px] overflow-auto'>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>SKU</h2>
                                                <input value={Sku} onChange={(e) => setSku(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center gap-10 px-5 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Manage stock?</h2>
                                                <div className=' gap-2 max-w-sm w-full  mt-5 lg:mt-0'>
                                                    <div className='flex flex-row self-start  items-center gap-3'>
                                                        <input onClick={() => setShowQuantity(!showQuantity)} type="checkbox" />
                                                        <h2 className=' text-xs  text-primaryGrayDark'>Manage stock level (quantity)</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {showQuantity == false ?
                                                <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                    <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Stock status</h2>
                                                    <div className='flex max-w-sm w-full flex-col gap-2'>
                                                        <select onChange={(e) => setSkuStatus(e.target.value)} className='p-1 w-full text-[14px]  text-primaryBlue focus:outline-primaryBlue focus:border-none border  rounded-sm'>
                                                            <option value={'In Stock'}>In Stock</option>
                                                            <option value={'Out of Stock'}>Out of Stock</option>
                                                            <option value={'On backorder'}>On backorder</option>
                                                        </select>
                                                    </div>
                                                </div> :
                                                <div className=''>
                                                    <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                        <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Stock Quantity</h2>
                                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                                    </div>
                                                    <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                        <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Stock status</h2>
                                                        <div className='flex max-w-sm w-full flex-col gap-2'>
                                                            <select className='p-1 w-full text-[14px]  text-primaryBlue focus:outline-primaryBlue focus:border-none border  rounded-sm'>
                                                                <option>Do Not Allow</option>
                                                                <option>Allow, but notify Customer</option>
                                                                <option>Allow</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                        <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Low Stock Threshold</h2>
                                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                                    </div>
                                                </div>
                                            }
                                            <div className='flex flex-col lg:flex-row lg:items-center gap-10 px-5 my-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Sold individually</h2>
                                                <div className=' gap-2 max-w-sm w-full  mt-5 lg:mt-0'>
                                                    <div className='flex flex-row self-start  items-center gap-3'>
                                                        <input type="checkbox" />
                                                        <h2 className=' text-xs  text-primaryGrayDark'>Limit purchases to 1 item per order
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                    {SwapCategories == 'Shipping' ?
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Weight (kg)</h2>
                                                <input type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center gap-10 px-5 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Dimensions (cm)</h2>
                                                <div className=' gap-2 max-w-sm w-full  mt-5 lg:mt-0'>
                                                    <div className='flex flex-row self-start justify-between items-center gap-3'>
                                                        <input type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="Length" />
                                                        <input type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="Width" />
                                                        <input type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="Height" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Stock status</h2>
                                                <div className='flex max-w-sm w-full flex-col gap-2'>
                                                    <select className='p-1 w-full text-[14px]  text-primaryBlue focus:outline-primaryBlue focus:border-none border  rounded-sm'>
                                                        <option>No Shipping Class</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div> : null}
                                    {SwapCategories == 'LinkedProducts' ?
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Upsells</h2>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Cross-sells</h2>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>

                                        </div> : null}
                                    {SwapCategories == 'Attribute' ?
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col lg:flex-row border-b-2 border-b-primaryDarkLight/55 pb-4 lg:items-center px-5 gap-3 mt-5 '>
                                                <select className='p-1 w-full text-sm max-w-48 text-primaryBlue focus:outline-primaryBlue focus:border-none border  rounded-sm'>
                                                    <option className='text-sm'>Age</option>
                                                    <option className='text-sm'>Brand</option>
                                                    <option className='text-sm'>Color</option>
                                                </select>
                                                <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                                                    <h6 className='text-sm font-semibold'>Add</h6>
                                                </div>
                                            </div>
                                            <div className='bg-primaryBlue rounded-sm flex flex-col items-center justify-center w-max my-5 mx-5 px-[7px] py-1'>
                                                <h5>Save Changes</h5>
                                            </div>

                                        </div> : null}
                                    {SwapCategories == 'Advanced' ?
                                        <div className='flex flex-col'>
                                            <div className='flex flex-col lg:flex-row  px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Purchase note</h2>
                                                <textarea type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col lg:flex-row lg:items-center px-5 gap-10 mt-5 '>
                                                <h2 className=' text-xs max-w-32 w-full text-primaryGrayDark'>Menu order</h2>
                                                <input type="number" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full  text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className='w-full border my-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b  bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product short description</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <textarea value={productDesc} onChange={(e) => setProductDesc(e.target.value)} className='w-full outline-none p-2 h-40 font-serif' />
                        </div>
                        <div className='w-full border bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b  bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product categories</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='  border w-[98%] my-2 mx-auto'>
                                <div className='flex flex-row items-center border-b py-2 pl-2 gap-4'>
                                    <h6 onClick={() => setCategoriesSwap('All')} className={`text-sm ${CategoriesSwap == 'All' ? "text-primaryBlue" : ""} cursor-pointer`}>All Categories</h6>
                                    {/* <h6 onClick={() => setCategoriesSwap('MostUsed')} className={`text-sm ${CategoriesSwap == 'MostUsed' ? "text-primaryBlue" : ""} cursor-pointer`}>Most Used</h6> */}
                                </div>
                                {/* {CategoriesSwap === 'All' ?
                                    <div className='w-full  my-3 flex flex-col gap-3 pl-3 h-48 overflow-auto'>
                                        {categories.map((e, index) =>
                                            <div key={index} className='flex flex-col gap-1'>
                                                <div className='flex flex-row items-center gap-2'>
                                                    <input type='checkbox' />
                                                    <h6 className='text-sm'>{e.cat1}</h6>
                                                </div>
                                                {e.cat1sub.map((e2) =>
                                                    <div className='flex pl-3 flex-row items-center gap-2'>
                                                        <input type='checkbox' />
                                                        <h6 className='text-sm'>{e2}</h6>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div> : */}
                                <div className='w-full my-3 flex flex-col gap-3 pl-3 h-48 overflow-auto'>
                                    {CategoryData.map((e, index) =>
                                        <div key={index} className='flex flex-col gap-1'>
                                            <div className='flex flex-row items-center gap-2'>
                                                <input onClick={() => setParentCategory(e.id)} checked={e.id == parentCategory ? true : false} type='checkbox' />
                                                <h6 className='text-sm'>{e.cat_name}</h6>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    <div className='2xl:w-[18%] w-full flex flex-col'>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className=' flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Publish</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='p-3 flex flex-col gap-2'>
                                <div className='flex flex-row items-center gap-2'>
                                    <FaRegEye className='text-primaryDarkLight' />
                                    <h5 className='text-primaryDarkLight'>Visibility: <span className='font-bold text-primaryGrayDark text-xs'>Public</span></h5>
                                </div>
                                <div className='flex flex-row items-center gap-2'>
                                    <FaCalendarDays className='text-primaryDarkLight' />
                                    <h5 className='text-primaryDarkLight'>Publish: <span className='font-bold text-primaryGrayDark text-xs'>Immediately</span></h5>
                                </div>
                            </div>
                            <div className='p-3  flex flex-col items-end'>
                                <div onClick={() => data != undefined ? PatchingFunction(data.product_id) : PostingTheProduct()} className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col items-center justify-center w-max px-[7px] py-1'>
                                    <h5>{data != undefined ? 'Update' : 'Publish'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product Video</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='p-3 flex flex-col gap-2'>
                                <label className='font-bold text-xs text-primaryGrayDark'>Video Url</label>
                                <input value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                            </div>

                        </div>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product tags</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='p-3 flex flex-col gap-2'>
                                <div className='flex flex-row items-center gap-2'>
                                    <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                                    <div className='border rounded-md md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                        <h6 className='text-sm font-semibold'>Add</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product Image</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='p-3 flex flex-col gap-2'>
                                <input onChange={(e) => {
                                    if (data !== undefined && data != null) {
                                        let temp = [...imageUpdateState]
                                        temp[0] = true
                                        setImageUpdateState(temp)
                                    }
                                    setProductImage(e.target.files[0])
                                }
                                } type="file" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                            </div>
                            {imageUpdateState[0] == true ? <img className='w-full h-40' src={URL.createObjectURL(ProductImage)} /> : data != undefined ? <img className='w-full h-40' src={"https://storage.googleapis.com/cloudsb/ProductImage/" + ProductImage} /> : ProductImage !== '' ? <div className='p-3 flex flex-col gap-2'>
                                <img className='w-full h-40' src={URL.createObjectURL(ProductImage)} />
                            </div> : null}
                        </div>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Product Gallery</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='p-3 flex flex-col gap-2'>
                                <input onChange={(e) => GalleryFunction(e)} multiple={true} type="file" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                            </div>
                            <div className='p-3 flex flex-row flex-wrap gap-2'>
                                {imageUpdateState[1] == true ? ProductGallery.map((e, index) => <img key={index} className='w-[45%] object-cover h-[100px] rounded-md' src={URL.createObjectURL(e)} />) : ProductGallery != null ? ProductGallery.map((e, index) =>
                                    data != undefined ? <img className='w-[45%] object-cover h-[100px] rounded-md' src={"https://storage.googleapis.com/cloudsb/ProductImage/" + e} /> : <img key={index} className='w-[45%] object-cover h-[100px] rounded-md' src={URL.createObjectURL(e)} />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProduct