import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'

function WooShiping() {
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                <div className='flex flex-row items-center gap-x-3'>
                    <h2 className='font-semibold text-lg text-primaryGrayDark'>Shipping zones</h2>
                    <div className='border md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                        <h6 className='text-sm font-semibold'>Add shipping zone</h6>
                    </div>
                </div>
            </div>
            <div className='my-5'>
                <h3 className='text-sm text-primaryDarkLight'>A shipping zone is a geographic region where a certain set of shipping methods are offered. WooCommerce will match a customer to a single zone using their shipping address and present the shipping methods within that zone to them.</h3>
                <div className='w-full my-3'>
                    <table className='w-full border border-primaryDarkLight '>
                        <colgroup>
                            <col className='w-[5%]  bg-gray-600 ' />
                            <col className='w-[25%] bg-gray-600 ' />
                            <col className='w-[50%] bg-gray-600 ' />
                            <col className='w-[20%] bg-gray-600 ' />
                        </colgroup>
                        <thead className='border-b border-b-primaryDarkLight'>
                            <tr>
                                <th>
                                    <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                                </th>
                                <th className=''>Zone name</th>
                                <th className=''>Region(s)</th>
                                <th>Shipping method(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                            collection.map((e, index) => */}
                            <tr className='p-4'>
                                <th className='pb-10'>
                                    <GiHamburgerMenu className='w-4 h-3 text-primaryDarkLight' />
                                </th>
                                <th className='text-primaryBlue pb-10'>India</th>
                                <th className='pb-10'>India</th>
                                <th className='pb-10'>Free shipping</th>
                            </tr>
                            {/* )
                        } */}
                        </tbody>
                        <tfoot className='border-t border-t-primaryDarkLight'>
                            <tr className='p-4'>
                                <th className='pb-10'>
                                    <GiHamburgerMenu className='w-4 h-3 text-primaryDarkLight' />
                                </th>
                                <th className='text-primaryBlue pb-10'>Locations not covered by your other zones</th>
                                <th className='pb-10'>This zone is optionally used for regions that are not included in any other shipping zone.</th>
                                <th className='pb-10'>No shipping methods offered to this zone.</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default WooShiping