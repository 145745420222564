import React from 'react'
import { BiSolidToggleRight } from 'react-icons/bi'
import { FaAngleDown, FaAngleUp, FaToggleOff } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'

function WooPayments() {
    const collection = [
        {
            id: 1,
            method: 'Check payments',
            desc: 'ake payments in person via checks. This offline gateway can also be useful to test purchases.'
        },
        {
            id: 2,
            method: 'Direct bank transfer',
            desc: 'ake payments in person via checks. This offline gateway can also be useful to test purchases.'
        },
        {
            id: 3,
            method: 'Cash on delivery',
            desc: 'Yes Have your customers pay with cash (or by other means) upon delivery.'
        },
    ]
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col mt-5 gap-3 '>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Payment Methods</h2>
                <h2 className=' text-sm text-primaryGrayDark'>Installed payment methods are listed below and can be sorted to control their display order on the frontend.</h2>
                <div className='w-full my-3 overflow-scroll'>
                    <table className='w-full border border-primaryDarkLight '>
                        <colgroup>
                            <col className='w-[5%]  ' />
                            <col className='w-[40%] ' />
                            <col className='w-[10%] ' />
                            <col className='w-[40%] ' />
                            <col className='w-[5%] ' />
                        </colgroup>
                        <thead className='border-b border-b-primaryDarkLight'>
                            <tr>
                                <th></th>
                                <th className='text-primaryGrayDark font-semibold'>Method</th>
                                <th className='text-primaryGrayDark font-semibold'>Enabled</th>
                                <th className='text-primaryGrayDark font-semibold'>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {collection.map((e, index) =>
                                <tr key={index} className={`${index%2==0?'':'bg-white'}`}>
                                    <td className='py-3'>
                                        <div className='flex flex-row items-center gap-3'>
                                            <GiHamburgerMenu className='w-4 h-3 text-primaryDarkLight' />
                                            <FaAngleDown className='w-4 h-4 text-primaryDarkLight' />
                                            <FaAngleUp className='w-4 h-4 text-primaryDarkLight' />
                                        </div>
                                    </td>
                                    <td className='text-primaryBlue py-5 font-bold'>{e.method}</td>
                                    <td className='py-3'>
                                        <BiSolidToggleRight className='text-[#935687] w-8 h-8' />
                                    </td>
                                    <td className='py-3 text-primaryDarkLight'>{e.desc}</td>
                                    <td className='py-3'>
                                        <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                                            <h6 className='text-sm font-semibold'>Manage</h6>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='bg-primaryBlue rounded-sm flex flex-col items-center justify-center w-max px-[7px] py-1'>
                        <h5>Save Changes</h5>
                    </div>
            </div>
        </div>
    )
}

export default WooPayments