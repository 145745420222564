import React, { useEffect, useState } from 'react'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { VscEye } from 'react-icons/vsc'
import WooOrderPopup from './WooOrderPopup'
import { useNavigate } from 'react-router-dom'
import AddnewOrder from './AddnewOrder'
import EditOrder from './EditOrder'
import axios from 'axios'

function WooCommerceOrder() {
    const [collection, setCollection] = useState()
    useEffect(() => {
        FetchingOrderData()
    }, [])

    const [orderData, setOrderData] = useState([])
    async function FetchingOrderData() {
        try {
            axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getOrders')
                .then((response) => {
                    if (response.status == 200) {
                        setOrderData(response.data)
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
        }
    }
    const [showAboutProduct, setShowAboutProduct] = useState(false)

    function showAboutProductFunction() {
        setShowAboutProduct(false)
    }
    const [showAddorder, setShowAddorder] = useState('')
    function AddOrderFunction() {
        setShowAddorder('AddOrder')
    }

    function NameReturnFunction(data) {
        let str = '';
        let count = 0;
        JSON.parse(data).forEach(element => {
            count++
            str += (element.N)
            if (count < JSON.parse(data).length) {
                str += ', '
            }
        });
        return str
    }
    function AmountReturnFunction(data) {
        let str = 0;
        JSON.parse(data).forEach(element => {
            str += (element.Q * element.P)
        });
        return str
    }
    return (
        <>
            {showAddorder == '' ?
                <div className='w-full bg-[#f0f0f1]'>
                    <div className='relative '>
                        {
                            showAboutProduct ? <WooOrderPopup collection={collection} showAboutProductFunction={showAboutProductFunction} /> : null
                        }
                    </div>
                    <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Orders</div>

                    <div className='my-5 flex flex-col md:flex-row md:items-center px-5 gap-1'>
                        <div className=' text-xl'>Orders</div>
                        {/* <div onClick={() => setShowAddorder('AddOrder')} className='border md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                        <h6 className='text-sm font-semibold'>Add Order</h6>
                    </div> */}
                    </div>
                    <div className='flex flex-col lg:flex-row gap-2 lg:items-center justify-between px-5'>
                        <div className='flex flex-row items-center gap-x-3'>
                            <h6 className='text-[13px] text-primaryGrayDark'>All <span className=''>(2)</span></h6>
                            <div className='text-primaryGrayDark'>|</div>
                            <h6 className='text-[13px] text-primaryBlue'> Processing <span className='text-primaryGrayDark'> (5)</span></h6>
                        </div>
                        <div className='flex flex-row items-center gap-x-2'>
                            <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                            <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[5px] cursor-pointer'>
                                <h6 className='text-sm line-clamp-1 font-semibold'>Search Products</h6>
                            </div>
                        </div>
                    </div>
                    <div className='w-full px-5 my-3 overflow-auto'>
                        <table className='w-full border border-primaryDarkLight '>
                            <colgroup>
                                <col className='w-[5%]  ' />
                                <col className='w-[10%] ' />
                                <col className='w-[35%] ' />                            
                                <col className='w-[10%] ' />
                                <col className='w-[30%] ' />
                                <col className='w-[10%] ' />
                            </colgroup>
                            <thead className='border-b border-b-primaryDarkLight'>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th className='  text-primaryGrayDark font-bold'>UserName</th>
                                    <th className='text-primaryBlue '>Order</th>
                                    <th className='text-primaryBlue '>Date</th>
                                    <th>Status</th>
                                    <th className='text-primaryBlue text-right'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderData.map((e, index) =>
                                        <tr style={{ verticalAlign: 'center' }} className={`${index % 2 != 0 ? 'bg-primaryWhite' : ''}`} key={index} >
                                            <td>{index + 1}</td>
                                            <td>{JSON.parse(e.address).N}</td>
                                            <td className='text-primaryBlue font-semibold px-3 py-4'>
                                                <div className='flex flex-row items-center justify-between'>
                                                    <h6 className=''>{NameReturnFunction(e.cart)}</h6>
                                                    <div onClick={() => {
                                                        setCollection(e)
                                                        setShowAboutProduct(true)
                                                    }} className='hover:border-2 p-[3px] rounded-md border-primaryBlue'>
                                                        <VscEye className='w-4 h-4 font-bold cursor-pointer' />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='px-3 py-4'>{new Date(e.posteddate).getUTCDate() + ' - ' + (new Date(e.posteddate).toLocaleDateString('en-US', { month: 'short' })) + ' - ' + new Date(e.posteddate).getUTCFullYear()}</td>
                                            <td className='px-3 py-4'>
                                                <div className='bg-primaryGreen/60 rounded-md px-4 py-2 w-max'>
                                                    <h6 className='text-primaryDarkLight text-sm'>Cash On Delivery</h6>
                                                </div>
                                            </td>
                                            <td className='text-primaryBlue text-right px-3 py-4'>{AmountReturnFunction(e.cart)}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <tfoot className='border-t border-t-primaryDarkLight'>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th className='  text-primaryGrayDark font-bold'>UserName</th>
                                    <th className='text-primaryBlue '>Order</th>
                                    <th className='text-primaryBlue '>Date</th>
                                    <th>Status</th>
                                    <th className='text-primaryBlue text-right'>Total</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div className='flex flex-row items-center gap-x-2 my-5'>
                            <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                                <option>Bulk action</option>
                                <option>Edit</option>
                                <option>move to trash</option>
                            </select>
                            <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                                <h6 className='text-sm font-semibold'>Apply</h6>
                            </div>
                        </div>
                    </div>
                </div> : showAddorder == 'AddOrder' ? <AddnewOrder /> : showAddorder == 'EditOrder' ? <EditOrder AddOrderFunction={AddOrderFunction} /> : null}
        </>
    )
}

export default WooCommerceOrder