import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiSolidFoodMenu } from 'react-icons/bi'
import { FaCaretDown, FaRegCheckSquare, FaStar } from 'react-icons/fa'
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight, FaArrowDown } from 'react-icons/fa6'
import { HiMiniPhoto } from 'react-icons/hi2'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import placeholder from '../Assets/placeholder.png'
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AllProducts({ Grid2Collection, Selectcategory }) {
  // mobile View
  const [ShowDetailIndex, setShowDetailIndex] = useState(-1)
  function IndexChangeingFunction(params) {
    if (ShowDetailIndex == -1) {
      setShowDetailIndex(params)
    } else {
      setShowDetailIndex(-1)
    }
  }
  const Headerinput = ['Name', 'SKU', 'Stock', 'Price', 'Categories', 'Tags']
  const [hoverItemShow, setHoverItemShow] = useState(-1)
  useEffect(() => {
    FetchingProductData()
    FetchingCategory()
  }, [])

  const [productData, setProductData] = useState([])
  async function FetchingProductData() {
    try {
      axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProducts')
        .then((response) => {
          if (response.status == 200) {
            setProductData(response.data)
            setFilterArray(response.data)
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {
      console.log(error.message);
    }
  }

  const [CategoryData, setCategoryData] = useState([])
  async function FetchingCategory() {
    try {
      await axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory')
        .then((response) => {
          if (response.status == 200) {
            setCategoryData(response.data)
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {

    }
  }
  async function DeleteFunction(_id) {
    try {
      axios.delete('https://cbdconsole-6zwsgvs5iq-el.a.run.app/deleteProducts/' + _id)
        .then((response) => {
          if (response.status == 200) {
            FetchingProductData()
          }
        }).catch((error) => {
          console.log(error);
        })
    } catch (error) {
      console.log(error.message);
    }
  }
  const [searchState, setSearchState] = useState('')
  const [FilterArray, setFilterArray] = useState([])
  function ProductSearchFunction(type, _id) {
    setPaggingIndex(0)
    let temp = [...productData]
    switch (type) {
      case 'Search':
        let result = temp.filter(e => e.product_name.toLowerCase().trim().match(searchState.toLowerCase().trim()))
        setFilterArray(result)
        if (result.length == 0) {
          toast('No data Found')
        }
        break;
      case 'Filter':
        if (_id == 0) {
          setFilterArray(productData)
        } else {
          let result1 = temp.filter(e => e.parent_category == _id)
          console.log(result1);
          setFilterArray(result1)
        }
        break;
      default:
        break;
    }
  }
  const [PaggingIndex, setPaggingIndex] = useState(0)
  return (
    <div className='w-full bg-[#f0f0f1] pb-10'>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
      <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Products</div>
      <div className='my-5 flex flex-col md:flex-row md:items-center px-5 gap-1'>
        <div className='text-2xl'>Products</div>
        <div className='flex flex-row items-center gap-x-3'>
          <div onClick={() => Selectcategory()} className='border md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
            <h6 className='text-sm font-semibold'>Add New</h6>
          </div>
          <div className='border border-primaryBlue text-primaryBlue px-2 py-1 cursor-pointer'>
            <h6 className='text-sm font-semibold'>Import</h6>
          </div>
          <div className='border border-primaryBlue text-primaryBlue px-2 py-1 cursor-pointer'>
            <h6 className='text-sm font-semibold'>Export</h6>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row gap-2 lg:items-center justify-between px-5'>
        <div className='flex flex-row items-center gap-x-3'>
          <h6 onClick={() => setFilterArray(productData)} className='text-[13px] cursor-pointer text-primaryBlue'>All <span className='text-primaryGrayDark'>({productData.length})</span></h6>
          {/* <div className='text-primaryGrayDark'>|</div> */}
          {/* <h6 className='text-[13px] text-primaryBlue'> Published<span className='text-primaryGrayDark'>(25)</span></h6> */}
          {/* <div className='text-primaryGrayDark'>|</div> */}
          {/* <h6 className='text-[13px] text-primaryBlue'>Sorting</h6> */}
        </div>
        <div className='flex flex-row items-center gap-x-2'>
          <input onChange={(e) => {
            setSearchState(e.target.value)
            if (e.target.value.length == 0) {
              setFilterArray([])
            }
          }} type="search" id="default-search"
            className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="Enter Product Name" />
          <div onClick={() => ProductSearchFunction('Search')} className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[5px] cursor-pointer'>
            <h6 className='text-sm line-clamp-1 font-semibold'>Search Products</h6>
          </div>
        </div>
      </div>
      <div className='flex flex-col xl:flex-row lg:gap-2 xl:items-center mt-2 justify-between px-5'>
        <div className='lg:flex hidden lg:gap-2 flex-col xl:flex-row xl:items-center gap-x-2'>
          {/* <div className='flex flex-row items-center gap-x-2'>
            <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
              <option>Bulk action</option>
              <option>Edit</option>
              <option>move to trash</option>
            </select>
            <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
              <h6 className='text-sm font-semibold'>Apply</h6>
            </div>
          </div> */}
          <div className='flex flex-row xl:items-center'>
            <select onChange={(e) => ProductSearchFunction("Filter", e.target.value)} className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
              <option value={0}>Select the Categories</option>
              {
                CategoryData.map((e, index) =>
                  <option key={index} value={e.id}>{e.cat_name}</option>
                )
              }
            </select>
            {/* <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
              <option>Filter By product type</option>
              <option>Edit</option>
              <option>move to trash</option>
            </select>
            <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
              <option>Filter By product status</option>
              <option>Edit</option>
              <option>move to trash</option>
            </select>
            <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
              <h6 className='text-sm font-semibold'>Filter</h6>
            </div> */}
          </div>
        </div>
        <div className='flex flex-row items-center gap-x-1'>
          <h5 className='text-primaryGrayDark'>{FilterArray.length} items</h5>
          {/* <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAnglesLeft className='w-2 h-2' />
          </div> */}
          <div onClick={() => {
            if (PaggingIndex != 0) {
              setPaggingIndex(PaggingIndex - 10)
            }
          }} className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAngleLeft className='w-2 h-2' />
          </div>
          {/* <input id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm py-[5px] text-center w-14 text-gray-900 rounded-sm bg-white " placeholder="" /> */}
          <h5 className='text-primaryGrayDark px-2'> {(PaggingIndex / 10) + 1} </h5>
          <div onClick={() => {
            if (PaggingIndex != FilterArray.length - FilterArray.length % 10) {
              setPaggingIndex(PaggingIndex + 10)
            }
          }} className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAngleRight className='w-2 h-2' />
          </div>
          {/* <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAnglesRight className='w-2 h-2' />
          </div> */}
        </div>
      </div>
      <div className='w-full md:block hidden px-5 my-3'>
        <table className='w-full border border-primaryDarkLight'>
          <colgroup>
            <col className='w-[5%]  ' />
            <col className='w-[5%] ' />
            <col className='w-[20%] ' />
            <col className='w-[10%] ' />
            <col className='w-[10%] ' />
            <col className='w-[10%] ' />
            <col className='w-[10%] ' />
            <col className='w-[10%] ' />
            <col className='w-[5%] ' />
            <col className='w-[15%] ' />
          </colgroup>
          <thead className='border-b border-b-primaryDarkLight'>
            <tr>
              <th>
                S.no
              </th>
              <th >
                <HiMiniPhoto className='w-5 h-4' />
              </th>
              <th className='text-primaryBlue '>{Headerinput[0]}</th>
              <th className='text-primaryBlue '>{Headerinput[1]}</th>
              <th >{Headerinput[2]}</th>
              <th className='text-primaryBlue '>{Headerinput[3]}</th>
              <th >{Headerinput[4]}</th>
              <th >{Headerinput[5]}</th>
              <th >
                <FaStar className='w-25 ' />
              </th>
              <th >Date</th>
            </tr>
          </thead>
          <tbody>
            {
              (FilterArray.length > 0 ? FilterArray : productData).slice(PaggingIndex, PaggingIndex + 10).map((e, index) =>
                <tr onMouseEnter={() => setHoverItemShow(index)} onMouseLeave={() => setHoverItemShow(-1)} style={{ verticalAlign: 'top' }} className={`${index % 2 != 0 ? 'bg-primaryWhite' : ''}`} key={index} >
                  <td>
                    {index + 1}
                  </td>
                  <td >
                    {e.thumbnail == '' ? <img className='w-[50px] h-[50px]' src={placeholder} /> : <img className='w-[50px] h-[50px]' src={"https://storage.googleapis.com/cloudsb/ProductImage/" + e.thumbnail} />}
                  </td>
                  <td>
                    <div className='flex flex-col'>
                      <h6 className='text-primaryBlue font-bold'>{e.product_name}</h6>
                      {hoverItemShow == index ? <h6 className='text-sm text-primaryBlue mt-1'><span className='text-primaryDarkLight'>ID:{e.product_id}</span>|<span onClick={() => Selectcategory(e)}> Edit</span>  | <span onClick={() => DeleteFunction(e.product_id)} className='text-primaryRed'>Trash</span> </h6> :
                        <h6 className='text-sm opacity-0 text-primaryBlue mt-1'><span className='text-primaryDarkLight'>ID:{e.product_id}</span>| Edit | <span className='text-primaryRed'>Trash</span> </h6>}
                    </div>
                  </td>
                  <td >{e.sku}</td>
                  <td className='text-primaryGreen font-bold'>In Stock</td>
                  <td >{e.sale_prize}</td>
                  <td className='text-primaryBlue'>{CategoryData.length > 0 && CategoryData.find(e1 => e1.id == e.parent_category).category_name}</td>
                  <td>-</td>
                  <td>
                    <FaStar className='w-5 h-5 text-primaryBlue' />
                  </td>
                  <td className=''>{e.Date}</td>
                </tr>
              )
            }
          </tbody>
          <tfoot className='border-t border-t-primaryDarkLight'>
            <tr>
              <th>
                <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
              </th>
              <th >
                <HiMiniPhoto className='w-5 h-4' />
              </th>
              <th className='text-primaryBlue '>{Headerinput[0]}</th>
              <th className='text-primaryBlue '>{Headerinput[1]}</th>
              <th >{Headerinput[2]}</th>
              <th className='text-primaryBlue '>{Headerinput[3]}</th>
              <th >{Headerinput[4]}</th>
              <th >{Headerinput[5]}</th>
              <th >
                <FaStar className='w-25 ' />
              </th>
              <th >Date</th>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* Mobile View */}
      <div className='w-full md:hidden block px-5 my-5'>
        <div className='border border-b-0    border-primaryDarkLight w-full '>
          <div className='w-full flex flex-row border-b bg-white border-b-primaryDarkLight'>
            <th>
              <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
            </th>
            <th className='text-primaryBlue '>{Headerinput[0]}</th>
          </div>
          {
            Grid2Collection.map((e, index) =>
              <div key={index} className={` ${index % 2 !== 0 ? 'bg-white' : ''}`}>
                <div key={index} className={`w-full flex flex-row items-start p-4 justify-between  `}>
                  <div className='flex flex-row items-center gap-2 '>
                    <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                    <h5 className='text-primaryBlue '>{e.title}</h5>
                  </div>
                  <div onClick={() => IndexChangeingFunction(index)} className=' h-5 w-5 rounded-full flex flex-col items-center justify-center border border-primaryBlue'>
                    <FaCaretDown className='w-4 h-4' />
                  </div>
                </div>
                {index == ShowDetailIndex ?
                  <div className='pl-12 w-full pr-5'>
                    <img className='w-10 h-10' src={e.firstImage} />
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Sku</h5>
                      <h5 className='text-primaryGrayDark text-start'>{e.Sku}</h5>
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Stock</h5>
                      <h5 className='text-primaryGreen font-bold'>{e.stock}</h5>
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Prize</h5>
                      <h5 className='text-primaryGrayDark'>{e.prize}</h5>
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Categories</h5>
                      <h5 className='text-primaryBlue'>{e.Categroy}</h5>
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Tags</h5>
                      <h5 className='text-primaryGrayDark'>{e.Tags}</h5>
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark '>Featured</h5>
                      <FaStar className='w-4 h-4 text-primaryBlue' />
                    </div>
                    <div className='flex flex-row items-center justify-between py-2'>
                      <h5 className='text-primaryGrayDark w-[50%] '>Date</h5>
                      <h5 className='text-primaryGrayDark  w-[50%]'>{e.Date}</h5>
                    </div>
                  </div> : null
                }
              </div>
            )
          }
        </div>
      </div>
      <div className='flex md:flex-row flex-col gap-2 md:items-center w-full px-5 justify-between'>
        <div className='flex flex-row items-center gap-x-2'>
          <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
            <option>Bulk action</option>
            <option>Edit</option>
            <option>move to trash</option>
          </select>
          <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
            <h6 className='text-sm font-semibold'>Apply</h6>
          </div>
        </div>
        {/* <div className='flex flex-row items-center gap-x-1'>
          <h5 className='text-primaryGrayDark'>25 items</h5>
          <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAnglesLeft className='w-2 h-2' />
          </div>
          <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAngleLeft className='w-2 h-2' />
          </div>
          <input id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm py-[5px] text-center w-14 text-gray-900 rounded-sm bg-white " placeholder="" />
          <h5 className='text-primaryGrayDark'>of 2</h5>
          <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAngleRight className='w-2 h-2' />
          </div>
          <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[8px] cursor-pointer'>
            <FaAnglesRight className='w-2 h-2' />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default AllProducts