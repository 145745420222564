import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaCloudDownloadAlt } from 'react-icons/fa'

function WooCommerceCustomer() {
    useEffect(() => {
        FetchingCustomerData()
        FetchingOrderData()
    }, [])

    const [customerData, setCustomerData] = useState([])
    async function FetchingCustomerData() {
        try {
            axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getUsers')
                .then((response) => {
                    if (response.status == 200) {
                        setCustomerData(response.data)
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
        }
    }
    const [orderData, setOrderData] = useState([])
    async function FetchingOrderData() {
        try {
            axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getOrders')
                .then((response) => {
                    if (response.status == 200) {
                        setOrderData(response.data)
                    }

                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {
        }
    }
    function FilteringOrdersDetails(_id, type) {
        if (type == 'orders') {
            const result = orderData.filter(e => e.user_id == _id);
            return result.length
        } else if (type == 'total') {
            const result = orderData.filter(e => e.user_id == _id);
            let count = 0;
            for (let i = 0; i < result.length; i++) {
                let Cart = JSON.parse(result[i].cart)
                for (let j = 0; j < Cart.length; j++) {
                    count += (Cart[j].Q * Cart[j].P);
                }
            }
            return count;
        }
    }
    return (
        <div className='w-full bg-[#f0f0f1]'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Customers</div>
            <div className='p-5'>
                <h5 className=' text-primaryGrayDark text-sm'>show:</h5>
                <form class="max-w-sm ">
                    <select class="block w-full mt-2 py-4 mb-6 text-sm text-primaryGrayDark font-semibold border outline-primaryBlue border-primaryDarkLight rounded-md bg-gray-50 ">
                        <option>All Customer</option>
                        <option>United States</option>
                        <option>Canada</option>
                        <option>France</option>
                        <option>Germany</option>
                    </select>
                </form>
                <div className='w-full px-8 py-3 bg-white border'>
                    <div className='flex flex-row gap-5 items-center'>
                        <h1 className='font-semibold'>Customers</h1>
                        <form class="w-full">
                            <div class="relative">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg class="w-4 h-4 text-primaryGrayDark dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="search" id="default-search" class="block w-full p-3 ps-10 text-sm text-gray-900 focus:border-none border border-gray-300 rounded-md bg-gray-50 focus:outline-primaryBlue" />
                            </div>
                        </form>
                        <div className='hidden md:flex flex-row items-center h-full text-primaryGrayDark cursor-pointer hover:text-primaryBlue gap-3'>
                            <FaCloudDownloadAlt className='w-5 h-5' />
                            <h3 className=' text-sm'>Download</h3>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white overflow-auto'>
                    <div className='w-full overflow-auto'>
                        <table className='w-full border  '>
                            <thead className='border-b '>
                                <tr>
                                    <th className=' text-center text-primaryGrayDark font-bold'>UserName</th>
                                    <th className=' text-center'>Business Name</th>
                                    <th className=' text-center'>Last active</th>
                                    <th className=' text-center'>Date Registered</th>
                                    <th className=' text-center text-primaryGrayDark font-bold'>Email</th>
                                    <th className=' text-center'>Orders</th>
                                    <th className=' text-center'>Total spend</th>
                                    {/* <th className=' text-center text-primaryGrayDark font-bold'>AOV</th> */}
                                    <th className=' text-center'>Customer Name</th>
                                    <th className=' text-center'>city</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerData.map((e, index) =>
                                        <tr key={index} className='border p-5'>
                                            <th className=' text-center'>{e.address != '' ? JSON.parse(e.address).N : 'Dummy'}</th>
                                            <th className=' text-center'>{e.address != '' ? JSON.parse(e.address).b_name : 'Not Yet order done'}</th>
                                            <th className=' text-center'>{new Date(e.updatedDate).getUTCDate() + '-' + (new Date().getUTCMonth(e.updatedDate) + 1) + '-' + new Date().getUTCFullYear(e.updatedDate)}</th>
                                            <th className=' text-center'>{new Date(e.creationDate).getUTCDate() + '-' + (new Date().getUTCMonth(e.creationDate) + 1) + '-' + new Date().getUTCFullYear(e.creationDate)}</th>
                                            <th className=' text-center text-primaryBlue'>{e.address != '' ? JSON.parse(e.address).email : 'Not Yet order done'}</th>
                                            <th className=' text-center'>{FilteringOrdersDetails(e.id, "orders")}</th>
                                            <th className=' text-center'>{FilteringOrdersDetails(e.id, "total")}</th>
                                            {/* <th className=' text-center'>{e.aov}</th> */}
                                            <th className=' text-center'>{e.address != '' ? JSON.parse(e.address).Cus : 'Not Yet order done'}</th>
                                            <th className=' text-center'>{e.address != '' ? JSON.parse(e.address).city : 'Not Yet order done'}</th>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WooCommerceCustomer