import React from 'react'

function WooAccount() {
    return (
        <div className='flex flex-col'>
            <div className='flex flex-col mt-5 gap-3 max-w-5xl'>
                <div className='flex flex-col lg:flex-row justify-between mt-5 w-full'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Guest checkouts</h2>
                    <div className='max-w-3xl w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Allow customers to place orders without an account</h2>
                        </div>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'> Allow customers to log into an existing account during checkout</h2>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 w-full'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Account creation</h2>
                    <div className='max-w-3xl w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Allow customers to create an account during checkout</h2>
                        </div>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Allow customers to create an account on the "My account" page</h2>
                        </div>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>When creating an account, automatically generate an account username for the customer based on their name, surname or email</h2>
                        </div>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>When creating an account, send the new user a link to set their password</h2>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 w-full'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Account erasure requests</h2>
                    <div className='max-w-3xl w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Remove personal data from orders on request</h2>
                        </div>
                        <h2 className=' text-sm text-primaryDarkLight mb-2'>When handling an account erasure request, should personal data within orders be retained or removed?</h2>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Remove access to downloads on request</h2>
                        </div>
                        <h2 className=' text-sm text-primaryDarkLight mb-2'>Adds an option to the orders screen for removing personal data in bulk. Note that removing personal data cannot be undone.</h2>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 w-full'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Personal data removal</h2>
                    <div className='max-w-3xl w-full flex flex-col gap-2 mt-5 lg:mt-0'>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Remove personal data from orders on request</h2>
                        </div>
                        <h2 className=' text-sm text-primaryDarkLight mb-2'>When handling an account erasure request, should personal data within orders be retained or removed?</h2>
                        <div className='flex  flex-row self-start  items-center gap-3'>
                            <input type="checkbox" />
                            <h2 className=' text-sm text-primaryGrayDark'>Remove access to downloads on request</h2>
                        </div>
                        <h2 className=' text-sm text-primaryDarkLight mb-2'>Adds an option to the orders screen for removing personal data in bulk. Note that removing personal data cannot be undone.</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-full'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Privacy policy</h2>
                <h2 className=' text-sm text-primaryGrayDark my-3'>This section controls the display of your website privacy policy. The privacy notices below will not show up unless a privacy page is set.</h2>
                <div className='flex flex-row mt-5 w-full max-w-5xl justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Registration privacy policy</h2>
                    <textarea type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 h-20 focus:border-none border  max-w-3xl w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-row mt-5 w-full max-w-5xl justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Checkout privacy policy</h2>
                    <textarea type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 h-20 focus:border-none border  max-w-3xl w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
            </div>
            <div className='flex flex-col w-full'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Personal data retention</h2>
                <h2 className=' text-sm text-primaryGrayDark my-3'>Choose how long to retain personal data when it’s no longer needed for processing. Leave the following options blank to retain this data indefinitely.</h2>
                <div className='flex flex-row mt-5 w-full max-w-[450px] justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Retain inactive accounts</h2>
                    <div className='flex flex-row items-center gap-2'>
                        <input type="number"  className=" focus:outline-primaryBlue w-24 focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="N/A" />
                        <select className='p-1 text-[14px] w-24  focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                            <option>month(s)</option>
                            <option>Week(s)</option>
                            <option>Year(s)</option>
                            <option>Day(s)</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-row mt-5 w-full max-w-[450px] justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Retain pending orders</h2>
                    <div className='flex flex-row items-center gap-2'>
                        <input type="number"  className=" focus:outline-primaryBlue w-24 focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="N/A" />
                        <select className='p-1 text-[14px] w-24  focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                            <option>month(s)</option>
                            <option>Week(s)</option>
                            <option>Year(s)</option>
                            <option>Day(s)</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-row mt-5 w-full max-w-[450px] justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Retain failed orders</h2>
                    <div className='flex flex-row items-center gap-2'>
                        <input type="number"  className=" focus:outline-primaryBlue w-24 focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="N/A" />
                        <select className='p-1 text-[14px] w-24  focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                            <option>month(s)</option>
                            <option>Week(s)</option>
                            <option>Year(s)</option>
                            <option>Day(s)</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-row mt-5 w-full max-w-[450px] justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Retain cancelled orders</h2>
                    <div className='flex flex-row items-center gap-2'>
                        <input type="number"  className=" focus:outline-primaryBlue w-24 focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="N/A" />
                        <select className='p-1 text-[14px] w-24  focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                            <option>month(s)</option>
                            <option>Week(s)</option>
                            <option>Year(s)</option>
                            <option>Day(s)</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-row mt-5 w-full max-w-[450px] justify-between'>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Retain completed orders</h2>
                    <div className='flex flex-row items-center gap-2'>
                        <input type="number"  className=" focus:outline-primaryBlue w-24 focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="N/A" />
                        <select className='p-1 text-[14px] w-24  focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                            <option>month(s)</option>
                            <option>Week(s)</option>
                            <option>Year(s)</option>
                            <option>Day(s)</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WooAccount