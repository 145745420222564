import React, { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import WooGeneral from './WooGeneral'
import WooProducts from './WooProducts'
import WooShiping from './WooShiping'
import WooPayments from './WooPayments'
import WooAccount from './WooAccount'
import WooTax from './WooTax'

function WoocommerceSettings({phoneNumber}) {
    const header = ["General", "Products", "Tax","Shipping", "Payments", "Account & Privacy", ]
    const [IndexFinder, setIndexFinder] = useState(0)
    
    return (
        <div className='w-full bg-[#f0f0f1]'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>{header[IndexFinder]}</div>
            <div className='lg:p-5 p-2 my-5 mb-20'>
                <div className='flex flex-row items-center flex-wrap gap-2 md:border-b lg:pl-2 border-primaryDarkLight'>
                    {
                        header.map((e, index) =>
                            <div onClick={() => setIndexFinder(index)} key={index}
                                className={`border ${IndexFinder == index ? 'bg-transparent text-primaryGrayDark' : 'text-primaryDarkLight'} md:border-b-0 hover:bg-white cursor-pointer bg-[#dcdcde] border-primaryDarkLight p-2`}>
                                <h3 className='text-sm font-semibold px-2'>{e}</h3>
                            </div>
                        )
                    }
                </div>
                {IndexFinder==0?<WooGeneral phoneNumber={phoneNumber}/>:IndexFinder==1?<WooProducts phoneNumber={phoneNumber}/>:IndexFinder==2?<WooTax/>:IndexFinder==3?<WooShiping/>:IndexFinder==4?<WooPayments/>:IndexFinder==5?<WooAccount/>:null}
            </div>
        </div>
    )
}

export default WoocommerceSettings