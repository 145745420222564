import React, { useState } from 'react'
import { FaAngleDown, FaAngleUp, FaPen } from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import gridimage from '../Assets/GirlImage1.jpg'
import { TiArrowSortedUp } from 'react-icons/ti'
function EditOrder({AddOrderFunction}) {
    const [DropDownState1, setDropDownState1] = useState(false)
    const [DropDownState, setDropDownState] = useState(false)
    const [SelectedStatus, setSelectedStatus] = useState('Select')
    const [addItemClicked, setaddItemClicked] = useState(false)
    return (
        <div className='w-full bg-[#f0f0f1]'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Edit order</div>
            <div className='my-5 flex flex-col md:flex-row md:items-center px-5 gap-1'>
                <div className=' text-xl'>Edit order</div>
                <div onClick={()=>AddOrderFunction()} className='border md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                    <h6 className='text-sm font-semibold'>Add Order</h6>
                </div>
            </div>
            <div className='flex xl:flex-row flex-col w-full px-5 mb-20'>
                <div className='flex xl:flex-row flex-col w-full gap-8 '>
                    <div className='w-full xl:w-[83%] flex flex-col gap-5'>
                        <div className='p-5 bg-white border border-primaryDarkLight flex flex-col'>
                            <div className=' text-xl font-normal'>Order #33182 details</div>
                            <div className='grid grid-cols-1 lg:grid-cols-3 mt-5 gap-8'>
                                <div className='flex flex-col'>
                                    <div className=' font-semibold text-sm'>General</div>
                                    <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Date created:</h5>
                                    <div className='flex flex-row items-center text-sm w-full gap-1'>
                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-[70%] focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />@
                                        <input type="number" id="default-search" className=" focus:outline-primaryBlue  w-[15%] focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />:
                                        <input type="number" id="default-search" className=" focus:outline-primaryBlue  w-[15%] focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                    </div>
                                    <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Status :</h5>
                                    <div className={`border bg-white rounded-md  ${DropDownState1 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                                        <div onClick={() => setDropDownState1(!DropDownState1)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[2px]'>
                                            <h1>{SelectedStatus}</h1>
                                            <FaAngleDown className='' />
                                        </div>
                                        {
                                            DropDownState1 ?
                                                <div className='flex flex-col'>
                                                    <h1 onClick={() => setDropDownState1(false) & setSelectedStatus('TamilNadu')} className='w-full bg-transparent hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                                    <h1 onClick={() => setDropDownState1(false) & setSelectedStatus('Andhara')} className='w-full bg-transparent hover:bg-primaryBlue p-2'>Andhara</h1>
                                                    <h1 onClick={() => setDropDownState1(false) & setSelectedStatus('karanataka')} className='w-full bg-transparent hover:bg-primaryBlue p-2'>karanataka</h1>
                                                    <h1 onClick={() => setDropDownState1(false) & setSelectedStatus('punjab')} className='w-full bg-transparent hover:bg-primaryBlue p-2'>punjab</h1>
                                                    <h1 onClick={() => setDropDownState1(false) & setSelectedStatus('mumbai')} className='w-full bg-transparent hover:bg-primaryBlue p-2'>mumbai</h1>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Customer :</h5>
                                    <div className={`border bg-white rounded-md  ${DropDownState ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm p-1 flex flex-col gap-2`}>
                                        <div onClick={() => setDropDownState(!DropDownState)} className='flex flex-row items-center cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                                            <h1>india</h1>
                                            <FaAngleDown className='' />
                                        </div>
                                        {
                                            DropDownState ?
                                                <div className='flex flex-col'>
                                                    <input type="search" id="default-search"
                                                        className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-2 text-gray-900 rounded-sm bg-white " placeholder="" />
                                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>Andhara</h1>
                                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>karanataka</h1>
                                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>punjab</h1>
                                                    <h1 onClick={() => setDropDownState(false)} className='w-full bg-transparent hover:bg-primaryBlue p-2'>mumbai</h1>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className=' font-semibold text-sm'>Billing</div>
                                        <FaPencil className='cursor-pointer text-primaryDarkLight w-3 h-3' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>First name</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Last name</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Company</h5>
                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Address line 1</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Address line 2</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>City</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Postcode / ZIP</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Country / Region</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>State / County</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Email address</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Phone</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Payment method:</h5>
                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                        <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Transaction ID:</h5>
                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className=' font-semibold text-sm'>Shipping</div>
                                        <FaPencil className='cursor-pointer text-primaryDarkLight w-3 h-3' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>First name</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Last name</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Company</h5>
                                        <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Address line 1</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Address line 2</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>City</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Postcode / ZIP</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Country / Region</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>State / County</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center text-sm w-full gap-5'>
                                            <div className='flex flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Phone</h5>
                                                <input type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                            <div className='flex opacity-0 flex-col w-full'>
                                                <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Email address</h5>
                                                <input disabled id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                            </div>
                                        </div>
                                        <h5 className='text-primaryDarkLight text-sm mt-3 mb-1'>Customer provided note</h5>
                                        <textarea type="search" id="default-search" className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border border-primaryDarkLight flex flex-col w-full'>
                            <table>
                                <colgroup>
                                    <col className='w-[70%] ' />
                                    <col className='w-[10%] ' />
                                    <col className='w-[10%] ' />
                                    <col className='w-[10%] ' />
                                </colgroup>
                                <thead className=' text-primaryDarkLight'>
                                    <tr >
                                        <th className='bg-[#f0f0f1]'>item</th>
                                        <th className='bg-[#f0f0f1]'>Cost</th>
                                        <th className='bg-[#f0f0f1]'>Qty</th>
                                        <th className='bg-[#f0f0f1]'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-white'>
                                        <td>
                                            <div className='flex flex-row w-full gap-3 items-center'>
                                                <img className='w-[50px] h-[50px]' src={gridimage} />
                                                <div className='flex flex-col gap-2'>
                                                    <a><h5 className='text-primaryBlue underline'>Teddy Bear Hat</h5></a>
                                                    <h6 className='text-xs font-bold text-primaryDarkLight'>Variation ID: <span className='text-primaryDarkLight font-normal'>14</span></h6>
                                                    <h6 className='text-xs font-bold text-primaryDarkLight'>color: <span className='text-primaryDarkLight font-normal'>green</span></h6>
                                                    <h6 className='text-xs font-bold text-primaryDarkLight'>size: <span className='text-primaryDarkLight font-normal'>Large</span></h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>₹19.99</td>
                                        <td>× 1</td>
                                        <td>₹19.99</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='flex flex-col w-full items-end'>
                                <div className='flex flex-col gap-3 max-w-60 py-5 w-full items-end '>
                                    <div className='flex flex-row items-center w-full gap-5'>
                                        <h6 className='text-sm w-[50%] text-primaryGrayDark'>Items Subtotal:</h6>
                                        <h6 className='text-sm w-[50%] text-primaryGrayDark font-bold'>₹0.00</h6>
                                    </div>
                                    <div className='flex flex-row items-center w-full gap-5'>
                                        <h6 className='text-sm w-[50%] text-primaryGrayDark'>Order Total:</h6>
                                        <h6 className='text-sm w-[50%] text-primaryGrayDark font-bold'>₹0.00</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row items-center p-5 border-t overflow-hidden border-t-primaryDarkLight justify-between'>
                                <div className={`flex ${addItemClicked ? '-translate-y-20 ' : 'translate-y-0 '} duration-300 flex-row items-center justify-between w-full`}>
                                    <div className='flex flex-row items-center gap-2'>
                                        <div onClick={() => setaddItemClicked(true)} className='border rounded-[4px] md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                            <h6 className='text-sm '>Add item</h6>
                                        </div>
                                        <div className='border rounded-[4px] md:ml-2 border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                            <h6 className='text-sm '>apply coupen</h6>
                                        </div>
                                    </div>
                                    <div className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col items-center justify-center w-max px-[7px] py-1'>
                                        <h5>Recalculate</h5>
                                    </div>
                                </div>
                                <div className={`flex flex-row ${addItemClicked ? 'translate-y-0 ' : '-translate-y-20 hidden'} duration-300 items-end gap-2 justify-end w-full`}>
                                    <div className='border rounded-[4px]  border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                        <h6 className='text-sm '>Add Product</h6>
                                    </div>
                                    <div className='border rounded-[4px]  border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                        <h6 className='text-sm '>Add Shiping</h6>
                                    </div>
                                    <div onClick={() => setaddItemClicked(false)} className='border rounded-[4px]  border-primaryBlue text-primaryBlue px-2 py-1  cursor-pointer'>
                                        <h6 className='text-sm '>Cancel</h6>
                                    </div>
                                    <div className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col items-center justify-center w-max px-2 py-[4px]'>
                                        <h5>save</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full xl:w-[18%]  flex flex-col bg-gray-500 h-12'>
                        <div className='w-full border mb-5 bg-white'>
                            <div className='flex flex-row px-3 py-2 items-center justify-between border-b border-b-primaryDarkLight bg-white gap-5 w-full'>
                                <div className='  flex flex-row items-center gap-5'>
                                    <h3 className='text-primaryGrayDark font-semibold text-sm'>Order notes</h3>
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    <FaAngleDown className='w-5 h-5 text-primaryDarkLight' />
                                    <FaAngleUp className='w-5 h-5 text-primaryDarkLight' />
                                    <TiArrowSortedUp className='w-5 h-5 text-primaryDarkLight' />
                                </div>
                            </div>
                            <div className='flex flex-col '>
                                <div className='border-b p-2 flex flex-col'>
                                    <div className='p-2 bg-[#efefef]'>
                                        <h5 className='text-primaryGrayDark text-xs'>note</h5>
                                    </div>
                                    <h6 className='text-primaryDarkLight text-xs my-1'>added on May 20, 2024 at 5:41 am by ashok b</h6>
                                    <h6 className='text-red-700 underline text-xs'>Delete note</h6>
                                </div>
                                <div className='flex flex-col gap-2 p-2'>
                                    <h5 className='text-primaryGrayDark text-sm'>Add note</h5>
                                    <textarea className=" focus:outline-primaryBlue  w-full focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-[4px] bg-white " placeholder="" />
                                    <div className='flex flex-row items-center gap-x-2'>
                                        <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                                            <option>Private note</option>
                                            <option>Note to customer</option>
                                        </select>
                                        <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                                            <h6 className='text-sm font-semibold'>Add</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditOrder