import React, { useState } from 'react'
import logoAdmin from '../Assets/logoAdmin.png'
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from './FirebaseInit';
import Sidebar from '../Screens/Sidebar';
import { useNavigate } from 'react-router-dom';
import Dahlialogo from '../Assets/Dahlialogo.png'
import Dahlianame from '../Assets/Dahlianame.png'
function Loginpage() {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [Otp, setOtp] = useState('')
    const [user, setUser] = useState(null)
    async function SendingOtpFunction() {
        const recaptcha = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        let num = '+91' + phoneNumber
        await signInWithPhoneNumber(auth, num, recaptcha)
            .then((confirmationResult) => {
                setUser(confirmationResult);
            }).catch((error) => {
                console.log(error);
            });
    }

    async function VerifyOTPFunction() {
        await user.confirm(Otp).then((result) => {
            console.log(result);
            <Sidebar/>
        }).catch((error) => {
            console.log(error.message);
        });
    }
    return (
        <div className='w-full h-[100vh] bg-[#f0f0f1] flex flex-col items-center'>
            <img className='w-[80px] h-[80px] mt-20' src={Dahlialogo} />
            <div className='bg-white p-5 flex mt-5 flex-col rounded-md border border-primaryDarkLight'>
                {user == null ? <>
                    <label className='font-semibold'>Enter Your Number</label>
                    <div className='flex flex-row border-2 rounded-md focus-within:border-primaryBlue gap-4 mt-5 p-3 items-center'>
                        <h3>+91</h3>
                        <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} maxLength={10} type='search' className='h-full tracking-wider outline-none' placeholder='Phone Number' />
                    </div>
                    <div className='mt-5' id='recaptcha-container' />
                    <div onClick={() => SendingOtpFunction()} className='border-2 cursor-pointer rounded-md flex flex-col items-center justify-center p-2 mt-5 border-primaryBlue text-black hover:text-white hover:bg-primaryBlue duration-200'>
                        <label className='text-base cursor-pointer font-bold'>Send OTP</label>
                    </div>
                </> :
                    <>
                        <label className='font-semibold'>Enter Your OTP Number</label>
                        <div className='flex flex-row border-2 rounded-md focus-within:border-primaryBlue gap-4 mt-5 p-3 items-center'>
                            {/* <h3>+91</h3> */}
                            <input value={Otp} onChange={(e) => setOtp(e.target.value)} type='search' className='h-full tracking-wider outline-none' placeholder='Enter Your OTP' />
                        </div>
                        {user!==null?null:<div className='mt-5' id='recaptcha-container' />}
                        <div onClick={() => VerifyOTPFunction()} className='border-2 cursor-pointer rounded-md flex flex-col items-center justify-center p-2 mt-5 border-primaryBlue text-black hover:text-white hover:bg-primaryBlue duration-200'>
                            <label className='text-base cursor-pointer font-bold'>Confirm OTP</label>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Loginpage