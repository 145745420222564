import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { FaRegClock } from 'react-icons/fa'

function WooCommerceHome() {
    return (
        <div className='w-full bg-[#f0f0f1]'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Home</div>
            <div className='flex flex-col  max-w-2xl gap-4 mx-auto my-10'>
                <h2 className='text-2xl md:px-0 px-2'>Let's get you started 🚀</h2>
                <h3 className='text-base md:px-0 px-2 text-primaryDarkLight'>Follow these steps to start selling quickly. 3 out of 6 complete.</h3>
                <div className='border  bg-white w-full'>
                    <div className='flex flex-row gap-5 p-8'>
                        <div className='md:w-[70%] w-full flex flex-col gap-3'>
                            <h2 className='text-2xl'>Add your tax rates</h2>
                            <h3 className='text-sm text-primaryDarkLight'>Set up tax rates manually or use WooCommerce and Jetpack to automate your sales tax calculations for you.</h3>
                            <div className='bg-primaryBlue rounded-sm flex flex-col items-center justify-center w-max p-2'>
                                <h5>Yes, please</h5>
                            </div>
                            <div className='flex flex-row items-center gap-2'>
                                <FaRegClock className='text-primaryDarkLight' />
                                <h3 className='text-sm text-primaryDarkLight'>1 minute</h3>
                            </div>
                        </div>
                        <img className='w-44 h-40 md:block hidden' src={'http://hatchbabyshop.com/wp-content/plugins/woocommerce/assets/images/task_list/tax-illustration.png'} />
                    </div>
                    <div className='border border-b-0 border-l-0 border-r-0 mt-5  '>
                        <div className='w-full hover:bg-white/25 cursor-pointer border-b flex flex-row items-center gap-5 p-3 px-8'>
                            <div className='bg-primaryBlue p-2 rounded-full'>
                                <AiOutlineCheck className='text-white'/>
                            </div>
                            <h5 className='text-primaryBlue font-bold'>You added store details</h5>
                        </div>
                        <div className='w-full border-b flex flex-row items-center gap-5 p-3 px-8'>
                            <div className='bg-primaryBlue p-2 rounded-full'>
                                <AiOutlineCheck className='text-white'/>
                            </div>
                            <h5 className='text-primaryBlue font-bold'>You added products</h5>
                        </div>
                        <div className='w-full border-b flex flex-row items-center gap-5 p-3 px-8'>
                            <div className='bg-primaryBlue p-2 rounded-full'>
                                <AiOutlineCheck className='text-white'/>
                            </div>
                            <h5 className='text-primaryBlue font-bold'>You set up payments</h5>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WooCommerceHome