import React, { useState } from 'react'
import { FaCommentAlt, FaHome, FaTimes } from 'react-icons/fa'
import { HiRefresh } from 'react-icons/hi'
import { IoLogoWordpress, IoMdAdd } from 'react-icons/io'
import logo1 from '../Assets/logo1.png'
import { RxHamburgerMenu } from 'react-icons/rx'
import { auth } from '../Components/FirebaseInit'
import { signOut } from 'firebase/auth'
import Loginpage from '../Components/Loginpage'
import Dahlialogo from '../Assets/Dahlialogo.png'
import Dahlianame from '../Assets/Dahlianame.png'
function Header({ SideBarOpenClose }) {
    const [showDropdown, setShowDropdown] = useState(false)
    function SignoutFunction() {
        signOut(auth).then(() => {
            setShowDropdown(false);
            <Loginpage/>
        }).catch((error) => {
            console.log(error.message);
        });
    }
    return (
        <div className='bg-primaryGrayDark sticky top-0 z-[100] lg:h-[32px] h-[46px] flex flex-row justify-between px-2'>
            <div className='flex flex-row items-center '>
                <RxHamburgerMenu onClick={() => SideBarOpenClose()} className='w-6 h-full mr-3 lg:hidden object-contain  hover:text-primaryBlue hover:bg-hoverColor cursor-pointer duration-300 text-primaryDarkLight' />
                {/* <IoLogoWordpress className='w-6 h-full hidden md:block object-contain  hover:text-primaryBlue hover:bg-hoverColor cursor-pointer duration-300 text-primaryDarkLight' /> */}
                <img className=' w-6 h-6' src={Dahlialogo}/>
                <div className='flex text-primaryDarkLight hover:text-primaryBlue px-3 hover:bg-hoverColor cursor-pointer h-full flex-row items-center gap-2'>
                    <FaHome className=' w-5 h-full' />
                    <h5 className='lg:block hidden'>Dahliacraft</h5>
                </div>
                {/* <div className='md:flex flex-row hidden text-primaryDarkLight hover:text-primaryBlue hover:bg-hoverColor cursor-pointer duration-300 h-full items-center gap-1 px-3'>
                    <HiRefresh className='w-5 h-full' />
                    <h5 className='lg:block hidden'>7</h5>
                </div>
                <div className='md:flex flex-row hidden text-primaryDarkLight hover:text-primaryBlue hover:bg-hoverColor cursor-pointer duration-300 h-full items-center gap-1 px-3'>
                    <FaCommentAlt className=' w-4 h-full' />
                    <h5 className='lg:block hidden'>170</h5>
                </div>
                <div className='flex flex-row text-primaryDarkLight hover:text-primaryBlue hover:bg-hoverColor cursor-pointer duration-300 h-full items-center gap-1 px-3'>
                    <IoMdAdd className=' w-5 h-full' />
                    <h5 className='lg:block hidden'>New</h5>
                </div> */}
            </div>
            <div className='flex  flex-row relative items-center gap-3'>
                <h5 className='lg:block hidden' onClick={() => setShowDropdown(!showDropdown)}>Howdy, ashok b</h5>
                <img onClick={() => setShowDropdown(!showDropdown)} className=' w-7 lg:w-5 cursor-pointer object-contain h-full' src={logo1} />
                {showDropdown ?
                    <div className='lg:hidden right-0 absolute w-[250px] top-[48px] gap-y-1 flex flex-col lg:top-[33px] px-5 py-3 bg-primaryGrayDark'>
                        <h4 className='text-white text-base'>Ashok b</h4>
                        <h5 className='text-primaryDarkLight'>Ashok</h5>
                        <h4 className='text-white text-base mt-3'>Edit Profile</h4>
                        <h4 className='text-white text-base mt-3'>logout</h4>
                    </div> : null}
                {showDropdown ?
                    <div className=' hidden right-0 absolute w-[250px] top-[48px] gap-y-1 lg:flex flex-row justify-around items-center lg:top-[33px] px-5 py-3  bg-primaryGrayDark'>
                        <img className='w-[80px] rounded-md h-[80px]' src={logo1} />
                        <div className='flex flex-col '>
                            <h5 className='text-white text-sm'>Ashok b</h5>
                            <h5 className='text-primaryDarkLight'>Ashok</h5>
                            <h4 className='text-white text-sm my-2'>Edit Profile</h4>
                            <h4 onClick={()=>SignoutFunction()} className='text-white cursor-pointer text-sm '>logout</h4>
                        </div>
                    </div> : null}
            </div>
        </div>
    )
}

export default Header