import React from 'react'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'

function ProductAttribute({ Grid2Collection }) {
    const Headerinput = ['Name', 'Slug','Type','Order by', 'Terms']
    return (
        <div className='w-full bg-[#f0f0f1] pb-10'>
            <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Attributes</div>
            <div className='p-5'>
                <div className=' text-2xl text-primaryGrayDark'>Attributes</div>
                <div className='w-full flex flex-col lg:flex-row  gap-10 mt-10'>
                    <div className='lg:w-[40%] w-full'>
                        <div className='font-semibold text-sm'>Add new attribute</div>
                        <h3 className='text-sm text-primaryDarkLight'>Attributes let you define extra product data, such as size or color. You can use these attributes in the shop sidebar using the "layered nav" widgets.</h3>
                        <div className='flex flex-col mt-5 '>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Name</h2>
                            <input type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-sm bg-white " placeholder="" />
                            <h3 className='text-sm text-primaryDarkLight'>Name for the attribute (shown on the front-end).</h3>
                        </div>
                        <div className='flex flex-col mt-5 '>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Slug</h2>
                            <input type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-sm bg-white " placeholder="" />
                            <h3 className='text-sm text-primaryDarkLight'>Unique slug/reference for the attribute; must be no more than 28 characters.</h3>
                        </div>
                        <div className='w-full flex flex-col gap-1 mt-5 '>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Type</h2>
                            <select className='p-1 text-sm w-max px-2 focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                                <option className='text-sm'>Select</option>
                                <option className='text-sm'>Color</option>
                                <option className='text-sm'>Image</option>
                                <option className='text-sm'>Label</option>
                            </select>
                            <h3 className='text-sm text-primaryDarkLight'>Determines how this attribute's values are displayed.</h3>
                        </div>
                        <div className='w-full flex flex-col gap-1 mt-5 '>
                            <h2 className=' text-sm w-full text-primaryGrayDark'>Default sort order</h2>
                            <select className='p-1 text-sm w-max px-2 focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                                <option className='text-sm'>Custom Ordering</option>
                                <option className='text-sm'>Name</option>
                                <option className='text-sm'>Name(Id)</option>
                                <option className='text-sm'>Label</option>
                            </select>
                            <h3 className='text-sm text-primaryDarkLight'>Determines the sort order of the terms on the frontend shop product pages. If using custom ordering, you can drag and drop the terms in this attribute.</h3>
                        </div>
                        <div className='flex flex-col my-5'>
                            <div className='bg-primaryBlue rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                                <h5>Add attribute</h5>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-[60%] w-full '>
                        <div className='w-full overflow-auto'>
                            <table className='w-full border border-primaryDarkLight'>
                                <colgroup>
                                    <col className='w-[17.5%]  ' />
                                    <col className='w-[17.5%] ' />
                                    <col className='w-[17.5%] ' />
                                    <col className='w-[17.5%] ' />
                                    <col className='w-[30%] ' />
                                </colgroup>
                                <thead className='border-b border-b-primaryDarkLight'>
                                    <tr>
                                        <th className='text-primaryBlue '>{Headerinput[0]}</th>
                                        <th className=' '>{Headerinput[1]}</th>
                                        <th >{Headerinput[2]}</th>
                                        <th className=' '>{Headerinput[3]}</th>
                                        <th className=' '>{Headerinput[4]}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Grid2Collection.map((e, index) =>
                                            <tr className={`${index % 2 != 0 ? 'bg-primaryWhite' : ''}`} key={index} >
                                                <td className='text-primaryBlue font-semibold'>age</td>
                                                <td>
                                                    <h6 className=''>{e.title}</h6>
                                                </td>
                                                <td >{e.desc}</td>
                                                <td className=''>{e.slug}</td>
                                                <td >{e.count}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductAttribute