import React, { useState } from 'react'
import { BiSolidFoodMenu } from 'react-icons/bi'
import { FaCaretDown, FaCaretLeft, FaRegCheckSquare, FaStar } from 'react-icons/fa'
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight, FaArrowDown } from 'react-icons/fa6'
import { HiMiniPhoto } from 'react-icons/hi2'
import { SiWoo } from 'react-icons/si'
import gridimage1 from '../Assets/GirlImage1.jpg'
import gridimage11 from '../Assets/GirlImage11.jpg'
import gridimage2 from '../Assets/GirlImage2.jpg'
import gridimage22 from '../Assets/GirlImage22.jpg'
import gridimage3 from '../Assets/GirlImage3.jpg'
import gridimage33 from '../Assets/GirlImage33.jpg'
import gridimage4 from '../Assets/GirlImage4.jpg'
import gridimage44 from '../Assets/GirlImage44.jpg'
import gridimage5 from '../Assets/GirlImage5.jpg'
import gridimage55 from '../Assets/GirlImage66.jpg'
import gridimage6 from '../Assets/GirlImage7.jpg'
import gridimage66 from '../Assets/GirlImage77.jpg'
import AllProducts from '../Components/AllProducts'
import WooCommerceHome from '../Components/WooCommerceHome'
import WooCommerceOrder from '../Components/WooCommerceOrder'
import WooCommerceCustomer from '../Components/WooCommerceCustomer'
import WoocommerceSettings from '../Components/WoocommerceSettings'
import AddProduct from '../Components/AddProduct'
import ProductCategory from '../Components/ProductCategory'
import ProductTags from '../Components/ProductTags'
import ProductAttribute from '../Components/ProductAttribute'
function Sidebar({ ShowBar,user }) {
    const PhoneNumber=user.phoneNumber.slice(3);
    const [ShowCategory, setShowCategory] = useState("Products")
    const products = ['All Products', 'Add New', 'Categories', 
        // 'Tags', 'Attribute'
    ]
    const WooCommerce = [
        'Home','Orders', 'Customers']
    const [SelectedProduct, setSelectedProduct] = useState(0)
    const [SelectedWooCommerce, setSelectedWooCommerce] = useState(0)

    const Grid2Collection = [
        {
            id: 1,
            firstImage: gridimage1,
            secondImage: gridimage11,
            title: 'Stuffed Blue Shark',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
        {
            id: 2,
            firstImage: gridimage2,
            secondImage: gridimage22,
            title: 'Denim Jumpsuit',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
        {
            id: 3,
            firstImage: gridimage3,
            secondImage: gridimage33,
            title: 'Super Star Knit Hat',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
        {
            id: 4,
            firstImage: gridimage4,
            secondImage: gridimage44,
            title: 'Denim Shorts',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
        {
            id: 5,
            firstImage: gridimage5,
            secondImage: gridimage55,
            title: 'Funny Bunny Jumpsuit',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
        {
            id: 6,
            firstImage: gridimage6,
            secondImage: gridimage66,
            title: 'Pooh Bear',
            prize: 'RS 39.99',
            stock: 'In Stock',
            Categroy: 'Uncategorized',
            Tags: "-",
            Sku: '-',
            Date: "Published 2019/02/18 at 1:57 pm"
        },
    ]

    const [editContent, setEditContent] = useState(null)
    function Selectcategory(params) 
    {
        setSelectedProduct(1)
        setEditContent(params)
    }

    function CategorySelection(params) 
    {
        setSelectedProduct(params)    
    }
    
    return (
        <div className='flex flex-row w-full bg-primaryGrayDark'>
            <div className={`${ShowBar ? '' : 'hidden lg:block'} w-[190px] lg:w-[180px] bg-primaryGrayDark h-screen flex flex-col`}>
                <div onClick={() => setShowCategory("Products")} className={`flex relative items-center ${ShowCategory == 'Products' ? 'bg-primaryBlue hover:text-primaryWhite' : ''} text-primaryWhite hover:text-primaryBlue flex-row p-3 mt-4 cursor-pointer hover:border-l-4 border-l-primaryBlue duration-75 items-center w-full`}>
                    <div className='flex flex-row gap-x-2'>
                        <BiSolidFoodMenu className='w-5  h-5' />
                        <h4 className=' text-sm'>Products</h4>
                    </div>
                    {ShowCategory == 'Products' ? <FaCaretLeft className='absolute h-8 w-8 -right-3 text-white' /> : null}
                </div>
                {ShowCategory == 'Products' ? <div className='w-full gap-y-2 flex py-2 bg-hoverColor flex-col'>
                    {
                        products.map((e, index) =>
                            <h5 onClick={() => {
                                setSelectedProduct(index)
                                setEditContent(null)
                            }} className={`${SelectedProduct == index ? 'text-white font-bold' : 'text-primaryDarkLight'} hover:border-l-4 px-3 hover:text-primaryBlue py-[.5px] border-l-primaryBlue duration-100 cursor-pointer`} key={index}>{e}</h5>
                        )
                    }
                </div> : null}
                <div onClick={() => {
                    setShowCategory("WooCommerce")
                    setEditContent(null)
                }} className={`flex items-center relative ${ShowCategory == 'WooCommerce' ? 'bg-primaryBlue hover:text-primaryWhite' : ''} text-primaryWhite overflow-hidden hover:text-primaryBlue flex-row p-3  cursor-pointer hover:border-l-4 border-l-primaryBlue duration-75 items-center w-full gap-x-2`}>
                    <div className='flex flex-row gap-x-2'>
                        <SiWoo className='w-5 h-5' />
                        <h4 className=' text-sm'>WooCommerce</h4>
                    </div>
                    {ShowCategory == 'WooCommerce' ? <FaCaretLeft className='absolute h-8 w-8 -right-3 text-white' /> : null}
                </div>
                {ShowCategory == 'WooCommerce' ? <div className='w-full gap-y-2 py-2 bg-hoverColor flex flex-col'>
                    {
                        WooCommerce.map((e, index) =>
                            <h5 onClick={() => setSelectedWooCommerce(index)} className={`${SelectedWooCommerce == index ? 'text-white font-bold' : 'text-primaryDarkLight'}  hover:text-primaryBlue hover:border-l-4 px-3 py-[.5px] border-l-primaryBlue duration-100 cursor-pointer`} key={index}>{e}</h5>
                        )
                    }
                </div> : null}
            </div>
            {ShowCategory == 'Products' ? SelectedProduct == 0 ? <AllProducts Selectcategory={Selectcategory} Grid2Collection={Grid2Collection} /> : SelectedProduct == 1 ? <AddProduct CategorySelection={CategorySelection} data={editContent} /> : SelectedProduct == 2 ? <ProductCategory Grid2Collection={Grid2Collection} />
                : SelectedProduct == 3 ? <ProductTags Grid2Collection={Grid2Collection} /> :SelectedProduct == 4 ? <ProductAttribute Grid2Collection={Grid2Collection} /> : null : ShowCategory == 'WooCommerce' ? SelectedWooCommerce == 0 ?
                    <WooCommerceHome /> : SelectedWooCommerce == 1 ? <WooCommerceOrder /> : SelectedWooCommerce == 2 ? <WooCommerceCustomer /> : SelectedWooCommerce == 3 ? <WoocommerceSettings phoneNumber={PhoneNumber}/> : null : null}
        </div>
    )
}

export default Sidebar
