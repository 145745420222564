import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Screens/Header';
import Sidebar from './Screens/Sidebar';
import { useEffect, useState } from 'react';
import AddnewOrder from './Components/AddnewOrder';
import WooCommerceOrder from './Components/WooCommerceOrder';
import EditOrder from './Components/EditOrder';
import Loginpage from './Components/Loginpage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from './Components/FirebaseInit';
function App() {

  const [ShowBar, setShowBar] = useState(false)
  function SideBarOpenClose() {
    if (ShowBar) {
      setShowBar(false)
    } else {
      setShowBar(true)
    }

  }

  const [user, setUser] = useState(null)
  useEffect(() => {
    onAuthStateChanged(auth, (data) => {
      console.log(data);
      if (data) {
        setUser(data)
        console.log(data);
      } else {
        setUser(data)
      }
    });
  }, [])


  return (
    <BrowserRouter>
      <Header SideBarOpenClose={SideBarOpenClose} />
      {user == null ? <Loginpage user={user}/> : <Sidebar ShowBar={ShowBar} user={user}/>}
      {/* <Routes>
        <Route path="AddnewOrder" element={<AddnewOrder />} />
        <Route path="WooCommerceOrder" element={<WooCommerceOrder />} />
        <Route path="EditOrder" element={<EditOrder />} />
      </Routes> */}
    </BrowserRouter>
  );
}

export default App;
