import React, { useState } from 'react'
import { FaAngleDown } from 'react-icons/fa'

function WooTax() {
    const [DropDownState, setDropDownState] = useState(false)
    const [DropDownState1, setDropDownState1] = useState(false)
    const [DropDownState2, setDropDownState2] = useState(false)
    const [DropDownState3, setDropDownState3] = useState(false)
    const [DropDownState4, setDropDownState4] = useState(false)

    // usestate
    const [PrizeWithTax, setPrizeWithTax] = useState(1)
    const [Rounding, setRounding] = useState(1)
    const [prizeOnShop, setPrizeOnShop] = useState('')
    const [prizeOnCart, setPrizeOnCart] = useState('')
    const [taxTotal, setTaxTotal] = useState('')

    return (
        <div className='flex flex-col'>
            <div className='flex flex-col mt-5 gap-3 max-w-xl'>
                <h2 className='font-semibold text-lg text-primaryGrayDark'>Tax Options</h2>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Prices entered with tax</h2>
                    <div className='flex flex-col gap-2 max-w-sm w-full'>
                        <div className='flex flex-row items-center gap-3'>
                            <input className='cursor-pointer' checked={PrizeWithTax==1?true:false} onClick={()=>setPrizeWithTax(1)} name="selected" type='radio' />
                            <h2 className='text-sm text-primaryGrayDark'>Yes, I will enter prices inclusive of tax</h2>
                        </div>
                        <div className='flex flex-row items-center gap-3'>
                            <input className='cursor-pointer' onClick={()=>setPrizeWithTax(0)} name="selected" type='radio' />
                            <h2 className=' text-sm text-primaryGrayDark'>No, I will enter prices exclusive of tax</h2>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Calculate tax based on</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm  flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState(!DropDownState)} className='flex flex-row items-center px-2 cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                            <h1 className='text-sm '>Text</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState ?
                                <div className='flex flex-col'>
                                    {/* <input type="search" id="default-search"
                                        className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-2 text-gray-900 rounded-sm bg-white " placeholder="" /> */}
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Andhara</h1>
                                    <h1 onClick={() => setDropDownState(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>karanataka</h1>

                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Shipping tax class</h2>
                    <div className={`border bg-white rounded-md  ${DropDownState1 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm  flex flex-col gap-2`}>
                        <div onClick={() => setDropDownState1(!DropDownState1)} className='flex flex-row items-center px-2 cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                            <h1 className='text-sm '>Text</h1>
                            <FaAngleDown className='' />
                        </div>
                        {
                            DropDownState1 ?
                                <div className='flex flex-col'>
                                    {/* <input type="search" id="default-search"
                                        className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-2 text-gray-900 rounded-sm bg-white " placeholder="" /> */}
                                    <h1 onClick={() => setDropDownState1(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 mt-2'>TamilNadu</h1>
                                    <h1 onClick={() => setDropDownState1(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Andhara</h1>
                                    <h1 onClick={() => setDropDownState1(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>karanataka</h1>

                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Rounding</h2>
                    <div className='flex flex-col gap-2 max-w-sm w-full'>
                        <div className='flex flex-row items-center gap-3'>
                            <input className='cursor-pointer' checked={Rounding==1?true:false} onClick={()=>setRounding(Rounding==1?0:1)} type='radio' />
                            <h2 className='text-sm text-primaryGrayDark'>Round tax at subtotal level, instead of rounding per line</h2>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Additional tax classes</h2>
                    <textarea type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Display prices in the shop</h2>
                    <div className='max-w-sm w-full gap-2  lg:mt-0'>
                        <div className={`border bg-white rounded-md  ${DropDownState2 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm  flex flex-col gap-2`}>
                            <div onClick={() => setDropDownState2(!DropDownState2)} className='flex flex-row items-center px-2 cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                                <h1 className='text-sm '>Text</h1>
                                <FaAngleDown className='' />
                            </div>
                            {
                                DropDownState2 ?
                                    <div className='flex flex-col'>

                                        <h1 onClick={() => setDropDownState2(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 '>Including Tax</h1>
                                        <h1 onClick={() => setDropDownState2(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Excluding Tax</h1>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm max-w-44 text-primaryGrayDark'>Display prices during cart and checkout</h2>
                    <div className='max-w-sm w-full gap-2  lg:mt-0'>
                        <div className={`border bg-white rounded-md  ${DropDownState3 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm  flex flex-col gap-2`}>
                            <div onClick={() => setDropDownState3(!DropDownState3)} className='flex flex-row items-center px-2 cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                                <h1 className='text-sm '>Text</h1>
                                <FaAngleDown className='' />
                            </div>
                            {
                                DropDownState3 ?
                                    <div className='flex flex-col'>

                                        <h1 onClick={() => setDropDownState3(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 '>Including Tax</h1>
                                        <h1 onClick={() => setDropDownState3(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Excluding Tax</h1>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
                    <h2 className='font-semibold text-sm text-primaryGrayDark'>Price display suffix</h2>
                    <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="N/A" />
                </div>
                <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
                    <h2 className='font-semibold text-sm max-w-44 text-primaryGrayDark'>Display prices during cart and checkout</h2>
                    <div className='max-w-sm w-full gap-2  lg:mt-0'>
                        <div className={`border bg-white rounded-md  ${DropDownState4 ? 'border-primaryBlue border-2' : 'border-primaryGrayDark '} w-full max-w-sm  flex flex-col gap-2`}>
                            <div onClick={() => setDropDownState4(!DropDownState4)} className='flex flex-row items-center px-2 cursor-pointer text-primaryGrayDark hover:text-primaryBlue justify-between py-[5px]'>
                                <h1 className='text-sm '>Text</h1>
                                <FaAngleDown className='' />
                            </div>
                            {
                                DropDownState4 ?
                                    <div className='flex flex-col'>

                                        <h1 onClick={() => setDropDownState4(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2 '>As a single total</h1>
                                        <h1 onClick={() => setDropDownState4(false)} className='w-full text-sm bg-transparent hover:text-white hover:bg-primaryBlue p-2'>Itemized</h1>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div  className='bg-primaryBlue cursor-pointer rounded-sm flex flex-col my-10 items-center justify-center w-max px-[7px] py-1'>
                    <h5>Save Changes</h5>
                </div>
            </div>
        </div>
    )
}

export default WooTax