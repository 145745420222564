// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCxpnz0k-zcFT83FFzt0KcFmZiSwU_qQKY",
  authDomain: "retailers-app-4ce09.firebaseapp.com",
  projectId: "retailers-app-4ce09",
  storageBucket: "retailers-app-4ce09.appspot.com",
  messagingSenderId: "974454253500",
  appId: "1:974454253500:web:f9a5a2e5bd2a2a68dc6db9",
  measurementId: "G-VTYNCMJHVZ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app)