import React, { useEffect, useState } from 'react'
import placeholder from '../Assets/placeholder.png'
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { HiMiniPhoto } from 'react-icons/hi2'

import axios from 'axios'
import { FaAngleDown } from 'react-icons/fa6'
function ProductCategory() {
  const [catName, setCatName] = useState('')
  const [catSlug, setCatSlug] = useState('')
  const [catLevel, setCatLevel] = useState(0)
  const [catDesc, setCatDesc] = useState('')
  const [ProductImage, setProductImage] = useState(null)
  const [ProductImage1, setProductImage1] = useState(null)
  const Headerinput = ['Name', 'Description', 'Slug', 'Count']

  async function PostTheCategory() {
    if (catName.length > 0 && catDesc.length > 0 && catSlug.length > 0 && ProductImage != null) {
      const data = {
        "cat_name": catName,
        "subtitle": catSlug,
        "cat_desc": catDesc,

      }
      try {
        await axios.post('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PostProductCategory', data)
          .then((response) => {
            console.log(response.data);
            AddtoStorage(ProductImage, response.data.insertId)
            // AddtoStorageBackground(ProductImage1, response.data.insertId)
          }).catch((error) => {
            console.log(error.message);
          })
      } catch (error) {
        console.log(error.message);
      }
    } else {
      alert('fill all the details')
    }
  }
  function AddtoStorage(e, _id) {
    const formdata = new FormData()
    formdata.append('file', e)
    try {
      axios.post('https://cbdconsole-6zwsgvs5iq-el.a.run.app/Upload/product_category', formdata, {
        headers:
        {
          "Content-Type": 'multipart/form-data'
        }
      }).then((responce) => {
        if (responce.status == 200) {
          console.log(responce.data);
          PatchingCategoryName(_id)
          setProductImage(null)
        }
      }).catch((error) => {
        console.log(error.message);
      })
    } catch (error) {
      console.log(error);
    }
  }

  function PatchingCategoryName(_id) {
    const data = {
      "thumbnail": ProductImage.name,
    }
    try {
      axios.patch('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PatchCategory/' + _id, data)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            setCatName('')
            setCatDesc('')
            setCatSlug('')
            setProductImage(null)
            FetchingCategory()

          }
        }).catch((error) => {
          console.log(error);
        })
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    FetchingCategory()
  }, [])

  const [CategoryData, setCategoryData] = useState([])
  async function FetchingCategory() {
    try {
      await axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getProductCategory')
        .then((response) => {
          if (response.status == 200) {
            setCategoryData(response.data)
            // RearrangeTheData(response.data)
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {
      console.log(error.message);
    }
  }

  const [hoverItemShow, setHoverItemShow] = useState(-1)
  const [showEditPage, setShowEditPage] = useState(false)
  const [ItemID, setItemID] = useState(-1)
  function OpenEditpageFunction(e) {
    setItemID(e.id)
    setShowEditPage(true)
    setCatName(e.cat_name)
    setCatSlug(e.subtitle)
    setCatDesc(e.cat_desc)
    setProductImage(e.thumbnail)
    // setProductImage1(e.background_image)
  }

  async function PatchingFunction() {
    if (catName.length > 0 && catDesc.length > 0 && catSlug.length > 0) {
      const data =
      {
        "cat_name": catName,
        "cat_desc": catDesc,
        "subtitle": catSlug
      }
      try {
        axios.patch('https://cbdconsole-6zwsgvs5iq-el.a.run.app/PatchCategory/' + ItemID, data)
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
              setShowEditPage(false)
              if (StateChanged) {
                AddtoStorage(ProductImage, ItemID)
              }
              setCatName('')
              setCatDesc('')
              setCatSlug('')
              setProductImage(null)
              FetchingCategory()
            }
          }).catch((error) => {
            console.log(error);
          })
      } catch (error) {
        console.log(error.message);
      }
    } else {
      alert('fill all the details')
    }
  }
  async function DeleteFunction(_id) {
    try {
      axios.delete('https://cbdconsole-6zwsgvs5iq-el.a.run.app/deleteProductCategory/' + _id)
        .then((response) => {
          if (response.status == 200) {
            // setShowEditPage(false)
            setProductImage(null)
            FetchingCategory()
          }
        }).catch((error) => {
          console.log(error);
        })
    } catch (error) {
      console.log(error.message);
    }
  }

  const [selectedCatName, setSelectedCatName] = useState('None')
  function CatLevelFunction(e, Name) {
    console.log(e);
    setCatLevel(e)
    setSelectedCatName(Name)
    setShowCatDropDown(false)
  }
  const [ShowCatDropDown, setShowCatDropDown] = useState(false)

  const [StateChanged, setStateChanged] = useState(false)
  function handleProductImage(e) {
    setProductImage(e)
    setStateChanged(true)
  }

  return (
    <>
      {showEditPage == false ?
        <div className='w-full bg-[#f0f0f1] pb-10'>
          <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Product categories</div>
          <div className='p-5'>
            <div className=' text-2xl text-primaryGrayDark'>Product categories</div>
            <div className='flex flex-col lg:flex-row gap-2 lg:items-center justify-between '>
              <div className='flex opacity-0 flex-row items-center gap-x-3'>
                <h6 className='text-[13px] text-primaryBlue'>All <span className='text-primaryGrayDark'>(25)</span></h6>
                <div className='text-primaryGrayDark'>|</div>
                <h6 className='text-[13px] text-primaryBlue'> Published<span className='text-primaryGrayDark'>(25)</span></h6>
                <div className='text-primaryGrayDark'>|</div>
                <h6 className='text-[13px] text-primaryBlue'>Sorting</h6>
              </div>
              <div className='flex flex-row items-center gap-x-2'>
                <input type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-sm bg-white " placeholder="" />
                <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[5px] cursor-pointer'>
                  <h6 className='text-sm line-clamp-1 font-semibold'>Search Products</h6>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col lg:flex-row gap-10'>
              <div className='lg:w-[40%] w-full'>
                <h3 className='text-sm my-3 text-primaryDarkLight'>Product categories for your store can be managed here. To change the order of categories on the front-end you can drag and drop to sort them. To see more categories listed click the "screen options" link at the top-right of this page.</h3>
                <div className='font-semibold text-sm'>Add new category</div>
                <div className='flex flex-col mt-5 '>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Name</h2>
                  <input value={catName} onChange={(e) => setCatName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                  <h3 className='text-sm text-primaryDarkLight'>Name for the attribute (shown on the front-end).</h3>
                </div>
                {/* <div className='flex flex-col mt-5 '>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Slug</h2>
                  <input value={catSlug} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                  <h3 className='text-sm text-primaryDarkLight'>The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</h3>
                </div>
                <div className='w-full flex flex-col gap-1 mt-5 '>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Parent Categories</h2>
                  <div onClick={() => setShowCatDropDown(!ShowCatDropDown)} className='bg-white max-w-[200px] flex flex-row items-center p-1 px-3 rounded-md justify-between'>
                    <h6 className='text-sm'>{selectedCatName}</h6>
                    <FaAngleDown />
                  </div>
                  {
                    ShowCatDropDown ?
                      <div className='max-w-[200px] rounded-md py-2 bg-white flex flex-col gap-3  scrollbar overflow-auto'>
                        <h6 onClick={() => CatLevelFunction(0, 'None')} className='text-sm pl-3 text-primaryBlue hover:bg-primaryBlue hover:text-white cursor-pointer'>None</h6>
                        {CategoryData.map((e, index) =>
                          <div key={index} className='flex flex-col text-primaryBlue '>
                            <div onClick={() => CatLevelFunction(e.id, e.cat_name)} className='flex flex-row items-center gap-2'>
                              <h6 className='text-sm cursor-pointer hover:bg-primaryBlue hover:text-white pl-3 w-full'>{e.cat_name}</h6>
                            </div>
                            {e.slice(1).map((e2, index2) =>
                              <div onClick={() => CatLevelFunction(e2.category_id, e2.category_name)} key={index2} className='flex flex-row items-center gap-2'>
                                <h6 className='text-sm cursor-pointer mt-2 hover:bg-primaryBlue hover:text-white pl-7 w-full'>{e2.category_name}</h6>
                              </div>
                            )}
                          </div>
                        )}
                      </div> : null
                  }
                  <h3 className='text-sm text-primaryDarkLight'>Assign a parent term to create a hierarchy. The term Jazz, for example, would be the parent of Bebop and Big Band.</h3>
                </div> */}

                <div className='flex flex-col mt-5 '>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Description</h2>
                  <textarea value={catDesc} onChange={(e) => setCatDesc(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 h-40 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                  <h3 className='text-sm text-primaryDarkLight'>The description is not prominent by default; however, some themes may show it.</h3>
                </div>
                <div className='flex flex-col mt-5 gap-3'>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Thumbnail</h2>
                  <div className='flex flex-row gap-5 w-full'>
                    {ProductImage == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> :
                      <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage)} />}
                    <input onChange={(e) => setProductImage(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                  </div>
                </div>
                {/* <div className='flex flex-col mt-5 gap-3'>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Title background image</h2>
                  <div className='flex flex-row gap-5 w-full'>
                    {ProductImage1 == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage1)} />}
                    <input onChange={(e) => setProductImage1(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
                  </div>
                </div> */}
                <div className='flex flex-col mt-5 '>
                  <h2 className=' text-sm w-full text-primaryGrayDark'>Subtitle</h2>
                  <input value={catSlug} onChange={(e) => setCatSlug(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
                </div>
                <div className='flex flex-col my-5'>
                  <div onClick={() => PostTheCategory()} className='bg-primaryBlue cursor-pointer rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                    <h5>Add new category</h5>
                  </div>
                </div>
              </div>
              <div className='lg:w-[60%] w-full '>
                <div className='flex flex-row items-center justify-between'>
                  <div className='flex flex-row items-center gap-2 mb-2'>
                    <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                      <option>Bulk action</option>
                      <option>Edit</option>
                      <option>move to trash</option>
                    </select>
                    <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                      <h6 className='text-sm font-semibold'>Apply</h6>
                    </div>
                  </div>
                  <h6 className='text-xs '>14 Items</h6>
                </div>
                <div className='w-full h-[600px] overflow-auto'>
                  <table className='w-full border border-primaryDarkLight'>
                    <colgroup>
                      <col className='w-[3%]  ' />
                      <col className='w-[10%] ' />
                      <col className='w-[20%] ' />
                      <col className='w-[40%] ' />
                      <col className='w-[20%] ' />
                      <col className='w-[5%] ' />
                    </colgroup>
                    <thead className='border-b border-b-primaryDarkLight'>
                      <tr>
                        <th>
                          <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                        </th>
                        <th >
                          <HiMiniPhoto className='w-5 h-4' />
                        </th>
                        <th className='text-primaryBlue '>{Headerinput[0]}</th>
                        <th className='text-primaryBlue '>{Headerinput[1]}</th>
                        <th >{Headerinput[2]}</th>
                        <th className='text-primaryBlue '>{Headerinput[3]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        CategoryData.map((e, index) =>
                          <tr onMouseEnter={() => setHoverItemShow(index)} onMouseLeave={() => setHoverItemShow(-1)} className={`${index % 2 != 0 ? 'bg-primaryWhite' : ''}`} key={index} >
                            <td>
                              <MdOutlineCheckBoxOutlineBlank className='w-5 h-5' />
                            </td>
                            <td >
                              <img className='w-[50px] h-[50px] ' src={"https://storage.googleapis.com/cloudsb/product_category/" + e.thumbnail} />
                            </td>
                            <td>
                              <h6 className='text-primaryBlue font-bold'>{e.cat_name}</h6>
                              {hoverItemShow == index ? <h6 className='text-sm text-primaryBlue mt-1'><span className='text-primaryDarkLight'>ID:2965</span>| <span onClick={() => OpenEditpageFunction(e)}>Edit</span>  | <span onClick={() => DeleteFunction(e.id)} className='text-primaryRed'>Trash</span> | Dublicate | Edit With Page Builder</h6> :
                                <h6 className='text-sm opacity-0 text-primaryBlue mt-1'><span className='text-primaryDarkLight'>ID:2965</span>| <span >Edit</span>  | <span className='text-primaryRed'>Trash</span> | Dublicate | Edit With Page Builder</h6>}
                            </td>
                            <td >{e.cat_desc}</td>
                            <td className=''>{e.category_slug}</td>
                            <td >{CategoryData.length}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
                <div className='flex flex-row items-center justify-between'>
                  <div className='flex flex-row items-center gap-2 mt-2'>
                    <select className='p-1 text-[14px] focus:outline-primaryBlue focus:border-none border border-primaryGrayDark rounded-sm'>
                      <option>Bulk action</option>
                      <option>Edit</option>
                      <option>move to trash</option>
                    </select>
                    <div className='border border-primaryBlue rounded-sm text-primaryBlue px-2 py-[3px] cursor-pointer'>
                      <h6 className='text-sm font-semibold'>Apply</h6>
                    </div>
                  </div>
                  <h6 className='text-xs '>14 Items</h6>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className='w-full bg-[#f0f0f1] pb-10'>
          <div className='bg-white p-5 font-semibold sticky w-full top-[46px] lg:top-[32px] text-sm'>Edit categories</div>
          <div className='p-5 max-w-xl '>
            <div className=' text-2xl mb-10 text-primaryGrayDark'>Edit category</div>
            <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5 '>
              <h2 className='font-semibold text-sm text-primaryGrayDark'>Name</h2>
              <input value={catName} onChange={(e) => setCatName(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
            </div>
            {/* <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
              <h2 className='font-semibold text-sm text-primaryGrayDark'>Slug</h2>
              <input value={catSlug} onChange={(e) => setCatSlug(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
            </div> */}
            {/* <div className='flex flex-col lg:flex-row  justify-between mt-5 '>
              <h2 className='font-semibold text-sm text-primaryGrayDark'>Parent category</h2>
              <div className='max-w-sm w-full flex flex-col'>
                <div onClick={() => setShowCatDropDown(!ShowCatDropDown)} className='bg-white max-w-[200px] mb-1 flex flex-row items-center p-1 px-3 rounded-md justify-between'>
                  <h6 className='text-sm'>{selectedCatName}</h6>
                  <FaAngleDown />
                </div>
                {
                  ShowCatDropDown ?
                    <div className='max-w-[200px] rounded-md py-2 bg-white flex flex-col gap-3  scrollbar overflow-auto'>
                      <h6 onClick={() => CatLevelFunction(0, 'None')} className='text-sm pl-3 text-primaryBlue hover:bg-primaryBlue hover:text-white cursor-pointer'>None</h6>
                      {CategoryData.map((e, index) =>
                        <div key={index} className='flex flex-col gap-1 pl-3'>
                          <div className='flex flex-row items-center gap-2'>
                            <input checked={e.id == ItemID} onClick={() => CatLevelFunction(e[0].id, e[0].cat_name)} type='checkbox' />
                            <h6 className='text-sm'>{e.cat_name}</h6>
                          </div>
                        </div>
                      )}
                    </div> : null
                }
              </div>
            </div> */}
            <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
              <h2 className='font-semibold text-sm text-primaryGrayDark'>Description</h2>
              <textarea value={catDesc} onChange={(e) => setCatDesc(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue focus:border-none border max-w-sm w-full border-primaryGrayDark text-sm px-3  py-[5px] text-gray-900 rounded-md bg-white " placeholder="" />
            </div>
            <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
              <h2 className='font-semibold text-sm text-primaryGrayDark'>Thumbnail</h2>
              <div className='max-w-sm flex flex-row items-center gap-3'>
                {StateChanged == false || ProductImage.length > 0 ? <img className='w-[70px] h-[70px] rounded-md' src={"https://storage.googleapis.com/cloudsb/product_category/" + ProductImage} /> :
                  <img className='w-[70px] h-[70px] rounded-md' src={URL.createObjectURL(ProductImage)} />
                }
                <input onChange={(e) => handleProductImage(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " />
              </div>
            </div>
            <div className='flex flex-col mt-5 '>
              <h2 className=' text-sm w-full text-primaryGrayDark'>Subtitle</h2>
              <input value={catSlug} onChange={(e) => setCatSlug(e.target.value)} type="search" id="default-search" className=" focus:outline-primaryBlue mt-2 focus:border-none border  w-full  text-sm px-3  py-[6px] border-primaryDarkLight text-gray-900 rounded-md bg-white " placeholder="" />
            </div>
            <div className='flex flex-col lg:flex-row lg:items-center justify-between mt-5'>
              {/* <h2 className='font-semibold text-sm text-primaryGrayDark'>Background Image</h2> */}
              <div className='max-w-sm flex flex-row items-center gap-3'>
                {/* {ProductImage1 == null ? <img className='w-[70px] h-[70px] rounded-md' src={placeholder} /> : <img className='w-[70px] h-[70px] rounded-md' src={"http://localhost:3100/images/"+ProductImage1} />}
                <input onChange={(e) => setProductImage1(e.target.files[0])} type="file" className=" focus:outline-primaryBlue h-7 text-sm  text-gray-900 rounded-sm " /> */}
              </div>
            </div>
            <div className='flex flex-row gap-5 items-center my-10'>
              <div onClick={() => PatchingFunction()} className='bg-primaryBlue cursor-pointer rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                <h5>Update</h5>
              </div>
              <h5 onClick={() => {
                setShowEditPage(false)
                setProductImage(null)
              }} className='text-black cursor-pointer hover:font-bold'>Cancel</h5>
            </div>
          </div>
        </div>}
    </>
  )
}

export default ProductCategory