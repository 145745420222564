import React from 'react'
import { FaTimes } from 'react-icons/fa'

function WooOrderPopup({ collection, showAboutProductFunction }) {
    function QuantityReturnFunction() {
        let str = 0;
        JSON.parse(collection.cart).forEach(element => {
            str += (element.Q)
        });
        return str
    } 
    function AmountReturnFunction() {
        let str = 0;
        JSON.parse(collection.cart).forEach(element => {
            str += (element.Q * element.P)
        });
        return str
    }
    return (
        <div className=' w-full flex flex-col h-[100vh] absolute justify-center items-center bg-transparent/70'>
            <div className='max-w-xl w-full bg-white shadow-lg  rounded-sm   '>
                <div className='flex border-b-2 flex-row items-center p-3 justify-between'>
                    <div className=' text-lg font-bold'>Order # {collection.id}</div>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='bg-primaryGreen/60 rounded-md px-4 py-2 w-max'>
                            <h6 className='text-primaryDarkLight text-sm'>Cash On Delivery</h6>
                        </div>
                        <div onClick={() => showAboutProductFunction()} className='p-3 hover:bg-primaryDarkLight hover:text-primaryGrayDark rounded-md text-primaryDarkLight duration-200 cursor-pointer flex flex-col items-center justify-center'>
                            <FaTimes className=' text-sm' />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col p-3 gap-1 '>
                    <div className=' text-base font-semibold'>Billing details</div>
                    <div className=' text-sm font-semibold mt-3'>Name</div>
                    <h6 className='text-[13px] text-primaryGrayDark mb-2'>{JSON.parse(collection.address).N}</h6>
                    <div className=' text-sm font-semibold'>Product List</div>
                    {
                        JSON.parse(collection.cart).map((e, index) =>
                            <h6 key={index} className='text-[13px] text-primaryGrayDark '>{e.N}  --{`>`} {e.Q} X {e.P} = {e.Q * e.P}</h6>
                        )
                    }
                    <div className=' text-sm font-semibold mt-2'>Business Name</div>
                    <h6 className='text-[13px] text-primaryGrayDark mb-2'>{JSON.parse(collection.address).b_name}</h6>
                    <div className=' text-sm font-semibold'>Customer Name</div>
                    <h6 className='text-[13px] text-primaryGrayDark '>{JSON.parse(collection.address).Cus}</h6>
                    <div className=' text-sm font-semibold'>City</div>
                    <h6 className='text-[13px] text-primaryGrayDark '>{JSON.parse(collection.address).city}</h6>
                </div>
                <div className='flex flex-col p-3 gap-1 '>
                    <div className=' text-sm font-semibold'>Email</div>
                    <a href=''><h5 className='text-primaryBlue underline font-semibold'>{JSON.parse(collection.address).email}</h5></a>
                </div>
                <div className='flex flex-col p-3 gap-1 '>
                    <div className=' text-sm font-semibold'>Phone</div>
                    <h5 className='text-primaryBlue underline font-semibold'>+919876543210</h5>
                </div>
                <div className='flex flex-col p-3 gap-1 '>
                    <div className=' text-sm font-semibold'>Payment via</div>
                    <h5 className='text-primaryGrayDark '>Cash on delivery</h5>
                </div>
                <div className='flex flex-row items-center justify-between p-3'>
                    <div className=' text-sm font-semibold'>Product: <span>{JSON.parse(collection.cart).length}</span></div>
                    <div className=' text-sm font-semibold'>Quantity: <span>{QuantityReturnFunction()}</span></div>
                    <div className=' text-sm font-semibold'>Total: <span>{AmountReturnFunction()}</span></div>
                </div>
                <div className='flex border-t-2 flex-row items-center p-3 justify-between'>
                    <div className='border border-primaryDarkLight hover:bg-gray-200 rounded-sm text-primaryBlue px-2 py-1  cursor-pointer'>
                        <h6 className='text-sm '>completed</h6>
                    </div>
                    <div className='flex flex-col '>
                        <div className='bg-primaryBlue rounded-[3px] flex flex-col items-center justify-center w-max px-[7px] py-1'>
                            <h5>Edit</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WooOrderPopup